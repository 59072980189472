.title-with-logo {
    position: relative;

    &::after {
        transition: 600ms all ease-in-out;
        background-image: url('../../frontend/images/icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        margin-left: 8px;
        height: 36px;
        width: 36px;

        content: '';
        opacity: 1;

        left: 100%;
        top: -20px;

        @include media-breakpoint-up(md) {
            margin-left: 16px;
            height: 72px;
            width: 72px;
        }
    }
}

.title-with-logo-star,
.title-with-logo-wrapper {
    max-width: calc(100vw - 120px);
    position: relative;
    margin: auto;

    @include media-breakpoint-up(md) {
        max-width: calc(100vw - 160px);
    }

    .line {
        display: inline-block;

        @include media-breakpoint-up(lg) {
            .story-caption & {
                display: block;
                float: right;
                clear: both;
            }
        }

        &:first-child {
            max-width: calc(100vw - 80px);
            position: relative;

            &::after {
                transition: 600ms all ease-in-out;
                background-image: url('../../frontend/images/icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                margin-left: 8px;
                height: 36px;
                width: 36px;
                content: '';
                opacity: 1;
                left: 100%;
                top: -20px;

                @include media-breakpoint-up(md) {
                    margin-left: 16px;
                    height: 72px;
                    width: 72px;
                }
            }
        }
    }
}

.title-with-logo-wrapper-small {
    max-width: calc(100vw - 120px);
    position: relative;
    margin: auto;

    .line {
        display: inline-block;

        @include media-breakpoint-up(lg) {
            display: block;
            float: right;
            clear: both;
        }

        &:first-child {
            max-width: calc(100vw - 80px);
            position: relative;

            &::after {
                transition: 600ms all ease-in-out;
                background-image: url('../../frontend/images/icon.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                position: absolute;
                margin-left: 8px;
                height: 36px;
                width: 36px;
                content: '';
                opacity: 1;
                left: 100%;
                top: -20px;
            }
        }
    }
}

.title-with-logo-small {
    position: relative;

    &::after {
        background-image: url('../../frontend/images/icon.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: -64px;
        height: 48px;
        width: 48px;
        content: '';
        top: -20px;
    }
}

.title-with-logo-top-left {
    position: relative;

    @include media-breakpoint-down(sm) {
        &::after {
            background-image: url('../../frontend/images/icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            height: 80px;
            width: 80px;
            content: '';
            top: -88px;
            left: 0;
        }
    }

    @include media-breakpoint-up(md) {
        &::after {
            background-image: url('../../frontend/images/icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            position: absolute;
            left: -96px;
            height: 128px;
            width: 128px;
            content: '';
            top: -120px;
        }
    }
}
