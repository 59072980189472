.info-popup {
    padding-bottom: ($spacer*2);
    padding-top: ($spacer*2);
    position: relative;

    @include media-breakpoint-up(md) {
        padding-bottom: ($spacer*5);
        padding-top: ($spacer*5);
    }

    [data-toggle-target] {
        cursor: pointer;
    }

    .text-default {
        &[data-toggle-target] {
            transition: 150ms all ease-in;

            &:hover {
                color: darken($default,10%) !important;
            }
        }
    }

    .info-popup-container {
        max-width: 90%; max-width: calc(100vw - #{$spacer*2});
        transform: translate(-50%,-50%) scale(0);
        max-height: calc(100vh - #{$spacer*2});
        border-radius: $border-radius-lg;
        transition: 500ms all ease-in-out;
        box-shadow: $box-shadow-lg;
        transform-origin: center;
        top: 50%; left: 50%;
        position: absolute;
        background: $white;
        padding: $spacer*2;
        text-align: left;
        overflow: auto;
        width: 800px;
        z-index: -1;
        opacity: 0;

        &.active {
            transform: scale(1) translate(-50%,-50%);
            transition: 500ms all ease-in-out;
            z-index: 999;
            opacity: 1;
        }

        .info-popup-container-close {
            transition: 150ms all ease-in;
            height: $spacer*1.2;
            position: absolute;
            width: $spacer*1.2;
            right: $spacer*2;
            cursor: pointer;
            top: $spacer*2;

            @include media-breakpoint-down(sm) {
                right: $spacer;
                top: $spacer;
            }

            &::before {
                background-color: $default;
                transform-origin: top left;
                transform: rotate(45deg);
                position: absolute;
                top: 0; left: 2px;
                content: '';
                height: 3px;
                width: 120%;
            }

            &::after {
                background-color: $default;
                transform-origin: top left;
                transform: rotate(-45deg);
                position: absolute;
                bottom: 0; left: 0;
                content: '';
                height: 3px;
                width: 120%;
            }

            &:hover {
                transform: rotate(90deg);

                &::after,
                &::before {
                    background-color: darken($default,20%);
                }
            }
        }
    }
}
