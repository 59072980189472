.social-follow {
    a {
        border: 1px solid $default;
        display: inline-block;
        text-align: center;
        border-radius: 50%;
        color: $default;
        padding: 0.2rem;
        height: 2.1rem;
        width: 2.1rem;
    }

    .facebook:hover a {
        border-color: $facebook;
        background: $facebook;
        color: $white;
    }

    .instagram:hover a {
        border-color: $instagram;
        background: $instagram;
        color: $white;
    }

    .linkedin:hover a {
        border-color: $linkedin;
        background: $linkedin;
        color: $white;
    }

    .pinterest:hover a {
        border-color: $pinterest;
        background: $pinterest;
        color: $white;
    }

    .twitter:hover a {
        border-color: $twitter;
        background: $twitter;
        color: $white;
    }

    .vimeo:hover a {
        border-color: $vimeo;
        background: $vimeo;
        color: $white;
    }

    .youtube:hover a {
        border-color: $youtube;
        background: $youtube;
        color: $white;
    }
}
