.login-section {
    border-bottom: 10px solid $off-white;
    max-height: calc(100vh - 80px);
    transition: 400ms all ease-in;
    transform: translateY(-150%);
    background-color: $white;
    padding-bottom: 24px;
    padding-top: 24px;
    margin-top: 80px;
    position: fixed;
    overflow: auto;
    z-index: 99;
    right: 0;
    left: 0;
    top: 0;

    @include media-breakpoint-up(md) {
        padding-bottom: 80px;
        padding-top: 80px;
    }

    .open-login & {
        box-shadow: 0 16px 32px rgba($dark,0.05);
        transition: 400ms all ease-in;
        transform: translateY(0);
    }
}
