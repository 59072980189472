.cta {
    &-image {
        mix-blend-mode: darken;

        article & {
            @include media-breakpoint-up(lg) {
                max-height: 240px;
            }
        }
    }

    &-sticky-button {
        position: fixed;
        z-index: 10;
        padding: 0;
        bottom: 0;
        top: auto;
        right: 0;
        left: 0;

        .btn {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            position: fixed;
            bottom: unset;
            right: unset;
            padding: 0;
            top: 364px;
            left: 0;

            .btn {
                width: auto;
            }
        }
    }
}
