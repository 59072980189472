// Slider
.slider-section {
    background-color: $white;
    position: relative;

    // Navigation color
    [class^="slider"] .slick-next::before,
    [class^="slider"] .slick-prev::before {
        text-shadow: 0 0 $spacer rgba(255,255,255,0.5);
        color: $default;
    }

    // Dots color
    .slick-dots {
        bottom: auto !important;
        z-index: 90 !important;
        right: ($spacer*1.5);
        top: $spacer;
        width: auto;
        left: auto;
    }

    .slick-dots li button::before,
    .slick-dots li.slick-active button::before {
        text-shadow: 0 0 $spacer rgba(255,255,255,0.5);
        color: $default;
    }

    .slide-title {
        font-weight: $font-weight-bold;
        line-height: 1.2em;
        font-size: 2rem;
        color: $default;
        display: block;

        @include media-breakpoint-up(sm) {
            line-height: 1.25em;
            font-size: 2.5rem;
        }

        @include media-breakpoint-up(md) {
            line-height: 1.15em;
            font-size: 3.5rem;
        }

        @include media-breakpoint-up(lg) {
            line-height: 0.95em;
            font-size: 4.5rem;
        }
    }

    // Slide
    .slide-item-row {
        @include media-breakpoint-down(sm) {
            .slide-title-image {
                margin: 0 auto;
                width: 96px;
            }

            .slide-title {
                text-align: center;
            }

            .slide-image-subline {
                text-align: center;
            }

            .slide-image {
                margin: 0 auto;
            }

            .slide-caption {
                > p:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
