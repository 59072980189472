.video {
    position: relative;

    .icon {
        text-shadow: 0 0 5px rgba(0,0,0,0.1);
        transform: translate(-50%,-50%);
        transition: 300ms all ease;
        vertical-align: middle;
        pointer-events: none;
        top: 50%; left: 50%;
        position: absolute;
        line-height: 1em;
        font-size: 48px;
        color: $white;
        z-index: 2;

        @at-root .video:hover .icon {
            transform: translate(-50%,-50%) scale(1.2);
        }

        @include media-breakpoint-up(md) {
            @at-root .video.video-with-overlay .icon {
                margin-top: -$spacer;
            }
        }
    }

    video {
        display: block;

        @at-root .video.active video {
            position: relative;
            z-index: 3;
        }
    }

    .video-poster {
        top: 0; left: 0; right: 0; bottom: 0;
        position: absolute;
        cursor: pointer;
    }

    .video-caption {
        border: 1px solid darken($light, 10%); border-top: 0;
        background: $white;
        padding: $spacer;

        @include media-breakpoint-up(md) {
            padding: ($spacer/2) ($spacer*0.75);
            bottom: $spacer;
            left: $spacer;
            right: $spacer;
            background: rgba($white, 0.95);
            position: absolute;
            color: $body-color;
        }
    }
}
