// Alert
.alert {
    margin-bottom: 1.5rem;
    @extend .mb-lc-0;

    &.alert-dismissible {
        > *:nth-last-child(2) {
            margin-bottom: 0 !important;
        }
    }

    &.alert-success {
        background-color: $success;
        border-color: $success;
        color: $white;
    }

    strong {
        color: inherit;
    }

    .icon-cross {
        position: absolute;
        top: 0; right: 0;
        padding: inherit;
        cursor: pointer;
    }

    ul {
        margin-top: 0.25rem;
        padding-left: 1rem;
    }
}
