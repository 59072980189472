// Breadcrumb
.breadcrumb {
    font-size: 0.8rem;

    @include media-breakpoint-down(sm) {
        white-space: nowrap;
        overflow: hidden;
        display: block;
        width: 100%;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .breadcrumb-item {
            display: inline;

            &::before {
                vertical-align: middle;
                margin-bottom: 3px;
            }
        }

        .breadcrumb-item + .breadcrumb-item {
            padding-left: .25rem;

            &::before {
                padding-right: .25rem;
            }
        }
    }

    .breadcrumb-item + .breadcrumb-item::before {
        font-family: 'base';
        margin-top: 0.1em;
        content: '\e008';
    }
}
