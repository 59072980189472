// Empty link
[href="#"]:not([id]) {
    outline: 2px dashed red;
}


// Icons
img,
div {
    &.icon {
        display: inline-block !important;
        vertical-align: middle;
        height: 1.5rem;
    }
    &.icon-2x {
        display: inline-block !important;
        vertical-align: middle;
        height: 2rem;
    }
    &.icon-3x {
        display: inline-block !important;
        vertical-align: middle;
        height: 3rem;
    }
}


// Backgrounds
.bg {
    &-default,
    &-primary,
    &-primary-light,
    &-secondary,
    &-secondary-light,
    &-tertiary,
    &-tertiary-light {
        color: $white;

        * {
            color: inherit;
        }
    }

    &-primary {
        .btn-outline-white:hover {
            color: $primary;
        }
    }

    &-primary-light {
        .btn-outline-white:hover {
            color: $primary-light;
        }
    }

    &-secondary {
        .btn-outline-white:hover {
            color: $secondary;
        }
    }

    &-secondary-light {
        .btn-outline-white:hover {
            color: $secondary-light;
        }
    }

    &-tertiary {
        .btn-outline-white:hover {
            color: $tertiary;
        }
    }

    &-tertiary-light {
        .btn-outline-white:hover {
            color: $tertiary-light;
        }
    }

    &-lines {
        &-1 {
            background-image: url('../../frontend/images/bg/lines-1.svg');
        }
        &-2 {
            background-image: url('../../frontend/images/bg/lines-2.svg');
        }
        &-3 {
            background-image: url('../../frontend/images/bg/lines-3.svg');
        }
        &-4 {
            background-image: url('../../frontend/images/bg/lines-4.svg');
        }
        &-5 {
            background-image: url('../../frontend/images/bg/lines-5.svg');
        }
        &-6 {
            background-image: url('../../frontend/images/bg/lines-6.svg');
        }
    }
}

[class*="bg-lines-"] {
    background-position: center 50px;
    background-repeat: no-repeat;
    background-size: 100vw;
}


// Typography
.text {
    &-sm {
        font-size: 0.85em;
    }

    &-medium {
        font-size: 1rem !important;
    }

    &-lg {
        font-size: 1.25em;
    }

    &-xl {
        font-size: 1.5em;
    }

    &-2x {
        font-size: 2em;
    }

    &-3x {
        line-height: 1em;
        font-size: 3em;
    }

    &-4x {
        line-height: 1em;
        font-size: 4em;
    }

    @include media-breakpoint-up(lg) {
        &-lg {
            &-sm {
                font-size: 0.85em;
            }
            &-lg {
                font-size: 1.25em;
            }
            &-xl {
                font-size: 1.5em;
            }
            &-2x {
                font-size: 2em;
            }
            &-3x {
                line-height: 1em;
                font-size: 3em;
            }
            &-4x {
                line-height: 1em;
                font-size: 4em;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        &-xl {
            &-sm {
                font-size: 0.85em;
            }
            &-lg {
                font-size: 1.25em;
            }
            &-xl {
                font-size: 1.5em;
            }
            &-2x {
                font-size: 2em;
            }
            &-3x {
                line-height: 1em;
                font-size: 3em;
            }
            &-4x {
                line-height: 1em;
                font-size: 4em;
            }
        }
    }
}

.lh {
    &-1 {
        line-height: 1 !important;
    }
}

.fw {
    &-medium {
        font-weight: 500 !important;
    }
}

.cursor-pointer {
    cursor: pointer !important;
}
