.btn-mobile-menu-alt {
    padding: ($spacer*1.2) ($spacer*0.7);
    transition: 300ms all ease-in;
    border: 1px solid $default;
    margin-left: $spacer;
    align-self: center;
    position: relative;
    cursor: pointer;
    display: flex;
    height: auto;
    width: auto;
    right: 0;
    left: 0;
    top: 0;

    @include media-breakpoint-up(md) {
        margin-left: ($spacer*2);
    }

    &:hover,
    .open-menu & {
        border: 1px solid darken($default,25%);
        background: rgba($default, 0.1);
        transition: 300ms all ease-in;

        .lines {
            background-color: darken($default,25%);
            transform: scale(0.9);

            &::after,
            &::before {
                background-color: darken($default,25%);
            }
        }
    }

    .lines {
        transition: 300ms all ease-in;
        background-color: $default;
        display: inline-block;
        width: ($spacer*1.5);
        position: relative;
        height: 3px;

        &::after,
        &::before {
            background-color: $default;
            position: absolute;
            height: inherit;
            width: inherit;
            content: '';
        }

        &::before {
            transition: 200ms all ease-in;
            top: -9px;

            .open-menu & {
                transform: rotate(45deg) scaleX(1.1);
                transition: 200ms all ease-in;
                transform-origin: top left;
                margin-left: 3px;
            }
        }

        &::after {
            transition: 200ms all ease-in;
            bottom: -9px;

            .open-menu & {
                transform: rotate(-45deg) scaleX(1.1);
                transition: 200ms all ease-in;
                transform-origin: bottom left;
                margin-left: 3px;
            }
        }

        .open-menu & {
            background-color: rgba($default, 0.1);
            transition: 200ms all ease-in;
        }
    }
}
