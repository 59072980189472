.dev {
    outline: 1px dashed red;
}

html {
    background-color: $off-white;
    min-height: 100vh;
}

body {
    padding-top: 80px;
}

.site-wrapper {
    overflow: hidden;
    width: 100%;
}

// Max cols
.col-max-sm,
.col-max-md,
.col-max-lg {
    padding-right: $spacer;
    padding-left: $spacer;
    width: 100%;
}

.col-max-sm {
    max-width: map_get($grid-breakpoints,sm);
}

.col-max-md {
    max-width: map_get($grid-breakpoints,md);
}

.col-max-lg {
    max-width: map_get($grid-breakpoints,lg);
}


// Col resets
.reset-col-md-8 {
    margin-left: calc(-25% - ((100vw - 150%) / 2));
    min-width: 100vw;
}


// Typography
.line {
    display: inline-block;
}

h1, .h1 {
    font-size: 1.5rem;
    color: $font;

    @include media-breakpoint-up(sm) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }
}

.display-1 {
    line-height: 1.1em;
    font-size: 2rem;
    color: $font;

    @include media-breakpoint-up(sm) {
        font-size: 4rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 5rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 6rem;
    }
}

h2, .h2 {
    font-size: 1.3rem;
    color: $font;

    @include media-breakpoint-up(sm) {
        font-size: 1.5rem;
    }

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }
}

h3, .h3 {
    color: $font;
}

h4, .h4 {
    font-weight: 500;
    color: $font;
}

h5, .h5 {
    font-weight: 500;
    color: $font;

    article & {
        line-height: inherit;
        font-size: inherit;
        margin-bottom: 0;
        font-weight: 700;
    }
}

h6, .h6 {
    color: $font;
}

b,
strong {
    color: $font;
}

.text-500 {
    font-weight: 500;
}

.text-700 {
    font-weight: 700;
}

// Link
.link-inverted {
    text-decoration: $link-hover-decoration;

    &:hover,
    a:hover & {
        text-decoration: $link-decoration;
    }
}


// Cursor helper
[data-toggle] {
    user-select: none;
    cursor: pointer;
}


// Icons
.icon {
    vertical-align: middle;
    margin-bottom: -0.2rem;
    display: inline-block;
    margin-top: -0.2rem;

    &:not(:only-child):first-child {
        margin-right: 0.3rem;
    }
}

.svg-icon {
    vertical-align: middle;
    margin-bottom: -0.2rem;
    display: inline-flex;
    margin-top: -0.2rem;
    height: 1em;
    width: 1em;

    &:not(:only-child):first-child {
        margin-right: 0.3rem;
    }

    &.svg-icon-small {
        height: 0.85rem;
        width: 0.85rem;
    }
}


// Improved inline list behaviour
.list-inline {
    li {
        @extend .list-inline-item;
    }
}


// Default fluid images
img {
    @extend .img-fluid;
}


// Buttons
.btn {
    transition: 200ms all ease-in-out;
    padding: .425rem 1.5rem .375rem;
    text-transform: uppercase;
    font-weight: 300;
    line-height: 1.2;
    font-size: 1rem;

    &-success {
        color: $white;
    }

    &-outline-white {
        &:hover {
            color: $font;
        }
    }

    &-outline-default {
        background: $white;

        &:hover {
            color: $white !important;
        }
    }

    &:not(.stretched-link):hover {
        box-shadow: $box-shadow;
        transform: scale(1.05);
    }

    &.btn {
        &-default,
        &-primary,
        &-primary-light,
        &-secondary,
        &-secondary-light,
        &-tertiary,
        &-tertiary-light {
            color: $white;
        }
    }

    .body_theme-color-primary & {
        &.btn-theme {
            background-color: $primary-light;
            color: $white;
        }
    }

    .body_theme-color-secondary & {
        &.btn-theme {
            background-color: $secondary-light;
            color: $white;
        }
    }

    .body_theme-color-tertiary & {
        &.btn-theme {
            background-color: $tertiary-light;
            color: $white;
        }
    }
}


/* Form styling */
span.req {
    color: $danger;
}

// Textarea
textarea.form-control {
    min-height: 8.6rem;
}

// Custom control label
.custom-control-label {
    &::after,
    &::before {
        top: 0.3em;
    }
}


// Label fix
label[for] {
    cursor: pointer;
}


// Last child margin fix
.mb-lc-0 > :last-child {
    margin-bottom: 0 !important;
}


// Bg light > card
.bg-light {
    .card {
        background: none;
    }
}

.embed-responsive {
    position: relative;
    cursor: pointer;

    img {
        display: none;
    }

    iframe[data-src] {
        background-color: rgba($white,0.5);
        pointer-events: none;

        + img {
            pointer-events: none;
            position: absolute;
            object-fit: cover;
            top: 0; left: 0;
            display: block;
            height: 100%;
            width: 100%;
        }
    }
}


// Data toggle styling
[data-toggle-target] {
    user-select: none;
    cursor: pointer;
}


// Lazyload
img {
    &.lazyload,
    &.lazyloading {
        transition: 300ms opacity;
        opacity: 0;
    }

    &.lazyloaded {
        transition: 300ms opacity;
        opacity: 1;
    }
}

.slider-media[class^="slider"] {
    .slick-prev,
    .slick-next {
        transition: 200ms box-shadow ease-in;
        background-color: $white;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;

        @include media-breakpoint-up(sm) {
            height: 3rem;
            width: 3rem;
        }

        &:hover {
            box-shadow: $box-shadow-sm;
        }

        &::before {
            color: $font !important;
        }
    }
    .slick-prev {
        left: 3rem;

        @include media-breakpoint-up(sm) {
            left: 4rem;
        }
    }
    .slick-next {
        right: 3rem;

        @include media-breakpoint-up(sm) {
            right: 4rem;
        }
    }
}

// Location map
.location-map-info {
    p:not(:last-child) {
        margin-bottom: ($spacer/2);
    }
}

// Shame
@media all and (min-width: 399px) {
    .col-xs-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
}

