.gallery-section {
    max-width: 1920px;
    margin: auto;

    .item-video {
        position: relative;

        .icon-youtube {
            transition: 200ms all ease-in-out;
            text-shadow: 0 0 0.2em rgba($dark,0.15);
            transform: translate(-50%,-50%);
            position: absolute;
            line-height: 0.8em;
            font-size: 64px;
            color: $white;
            left: 50%;
            top: 50%;

            @media all and (max-width: 640px) {
                font-size: 40px;
            }
        }

        a:hover {
            .icon-youtube {
                text-shadow: 0 0 0.2em rgba($dark,0.25);
                font-size: 80px;

                @media all and (max-width: 640px) {
                    font-size: 52px;
                }
            }
        }
    }
}
