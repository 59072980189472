.highlight-section {

    .container-fluid {
        max-width: 1480px;

        @include media-breakpoint-down(sm) {
            padding-right: 0;
            padding-left: 0;

            figure {
                margin: 0;
            }
        }
    }
}
