.menu-section {
    border-bottom: 10px solid $off-white;
    top: 0; left: 0; right: 0;
    max-height: calc(100vh - 80px);
    transition: 400ms all ease-in;
    transform: translateY(-150%);
    background-color: $white;
    padding-bottom: 24px;
    padding-top: 24px;
    margin-top: 80px;
    position: fixed;
    overflow: auto;
    z-index: 99;

    @include media-breakpoint-up(md) {
        padding-bottom: 80px;
        padding-top: 80px;
    }

    .open-menu & {
        box-shadow: 0 16px 32px rgba($dark,0.05);
        transition: 400ms all ease-in;
        transform: translateY(0);
    }

    .mobile-login-menu {
        .icon {
            transition: 100ms all ease-in;
            line-height: 0.5rem;
        }

        .list-block {
            font-size: 14px;
        }

        .active {
            .h6 {
                .icon {
                    transition: 100ms all ease-in;
                    transform: rotate(90deg);
                    transform-origin: center;
                }
            }

            .list-block {
                display: block !important;
            }
        }
    }

    .menu {

        .active > a,
        .active > .submenu > a{
            font-weight: 500;
        }

        ul {
            list-style: none;
            font-size: 16px;
            padding: 0;
            margin: 0;

            @include media-breakpoint-up(md) {
                font-size: 19px;
            }

            li {
                margin: ($spacer*0.75) 0;
                padding: 0;

                a {
                    text-decoration: none;

                    span {
                        position: relative;

                        &::after {
                            transition: 300ms all ease-in;
                            bottom: -2px; left: 0;
                            position: absolute;
                            background: $font;
                            content: '';
                            height: 1px;
                            width: 0;
                        }

                        &:hover {
                            &::after {
                                width: 100%;
                            }
                        }
                    }
                }

                &.active {
                    > a,
                    > .submenu > a {
                        span {
                            &::after {
                                height: 2px;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            ul {
                margin-left: ($spacer*1.5);
            }
        }
    }

    .socialmedia {
        margin: 0;

        li {
            margin-bottom: $spacer;
        }

        a {
            border: 1px solid $default;
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            color: $default;
            padding: 0.2rem;
            height: 2.15rem;
            width: 2.15rem;
        }

        .icon {
            margin-top: 0;
        }

        .facebook:hover a {
            border-color: $facebook;
            background: $facebook;
            color: $white;
        }

        .instagram:hover a {
            border-color: $instagram;
            background: $instagram;
            color: $white;
        }

        .linkedin:hover a {
            border-color: $linkedin;
            background: $linkedin;
            color: $white;
        }

        .twitter:hover a {
            border-color: $twitter;
            background: $twitter;
            color: $white;
        }

        .vimeo:hover a {
            border-color: $vimeo;
            background: $vimeo;
            color: $white;
        }

        .youtube:hover a {
            border-color: $youtube;
            background: $youtube;
            color: $white;
        }
    }

    .form-control {
        color: $default;

        &::placeholder {
            color: $default;
        }
    }

    .input-group-text {
        color: $default;
    }

    .language-select {
        a {
            @extend .link-inverted;
            font-size: 17px;
        }

        .active {
            font-weight: 500;
        }
    }
}
