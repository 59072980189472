.slider-news {

    @media all and (max-width: 560px) {
        margin-right: -1rem;
        margin-left: -1rem;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-track {
        align-items: stretch;
        flex-direction: row;
        display: flex;

        .slide-item {
            overflow: visible;
            height: auto;
            flex: 1;

            .card {
                margin-bottom: 0;
                height: 100%;
            }
        }
    }
}
