.c2a-section {
    position: relative;
    z-index: 2;

    .c2a-section-title {
        max-width: 350px;
    }

    &__content {
        flex-direction: row;
        display: flex;
    }

    a {
        flex-basis: auto;

        svg {
            transition: 150ms all ease-in;
            margin: 0 auto $spacer;
            transform: scale(1);
            max-width: 4rem;
            display: block;
            height: 3rem;

            @include media-breakpoint-up(md) {
                height: 4rem;
                max-width: 5rem;
            }
        }

        &:hover {
            svg {
                transition: 150ms all ease-in;
                transform: scale(1.1);
            }
        }
    }
}
