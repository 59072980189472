.news-item {
    flex-direction: column;
    align-items: stretch;
    display: flex;

    .card {
        transition: 300ms all ease-in;
        margin-bottom: ($spacer*1.5);
        height: 100%;
        border: 0;

        &:hover {
            transition: 300ms all ease-in;
            transform: scale(1.025);
            box-shadow: $box-shadow;
        }

        @include media-breakpoint-up(sm) {
            margin-bottom: ($spacer*2);
        }
    }

    .news-image {

    }

    .news-caption {
        transition: 300ms background ease-in;
        background-color: $default;
        flex-direction: column;
        padding: ($spacer*1.5);
        display: flex;
        color: $white;

        @include media-breakpoint-up(sm) {
            padding: ($spacer*2);
        }

        &:hover {
            background-color: darken($default,7%) !important;
        }

        &.bg-primary {
            &:hover {
                background-color: darken($primary,7%) !important;
            }
        }

        &.bg-primary-light {
            &:hover {
                background-color: $primary !important;
            }
        }

        &.bg-secondary {
            &:hover {
                background-color: darken($secondary,7%) !important;
            }
        }

        &.bg-secondary-light {
            &:hover {
                background-color: $secondary !important;
            }
        }

        &.bg-tertiary {
            &:hover {
                background-color: darken($tertiary,7%) !important;
            }
        }

        &.bg-tertiary-light {
            &:hover {
                background-color: $tertiary !important;
            }
        }

        .news-link {
            &:last-child {
                margin-top: auto;
            }
        }

        > * {
            color: inherit;
        }
    }
}

.visible, .invisible {
    opacity: 0.0;
    transition: opacity 0.5s ease;
}

.visible {
    opacity: 1.0;
}
