// Footer
.footer-section {
    background-color: $off-white;
    color: $font;

    .footer-menu {
        @extend .list-inline;

        a {
            @extend .link-inverted;
        }

        @include media-breakpoint-up(md) {
            li {
                &:not(:first-child) {
                    &::before {
                        margin-left: -0.25rem;
                        margin-right: 0.5rem;
                        content: '|';
                    }
                }
            }
        }

        @include media-breakpoint-down(sm) {
            li {
                margin: ($spacer/2) 0;
                display: block;
            }
        }
    }
}
