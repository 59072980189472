article {

    // Default figure styling
    figure {
        @extend .figure;

        &[style*="text-align: center"] {
            display: block;
        }

        img {
            @extend .figure-img;

            @include media-breakpoint-down(lg) {
                height: auto !important;
            }
        }

        figcaption {
            @extend .figure-caption;
            font-style: italic;

            &::before {
                content: '— ';
            }
        }
    }

    // Blockquote
    blockquote {
        @extend .blockquote;
        background-color: $primary-light;
        padding: ($spacer*1.5);
        margin-bottom: 1.5rem;
        color: $white;

        > *:not([class]) {
            color: $white;
        }

        footer {
            @extend .blockquote-footer;
            color: $white;

            strong {
                color: inherit;
            }
        }
    }

    // Default ordered list styling
    ol {
        counter-reset: number;
        margin: 0 0 1.5rem;
        list-style: none;
        padding: 0;

        li {
            counter-increment: number;
            margin: 0 0 0 1.5rem;
            padding: 0;

            &::before {
                content: counters(number, '.') '. ';
                display: inline-block;
                margin-left: -1.5rem;
                font-weight: bold;
                width: 1.5rem;
                float: left;
            }
        }

        // Latin styling
        &.counter-upper-latin {
            counter-reset: alphabet;

            li {
                counter-increment: alphabet;

                &::before {
                    content: counter(alphabet, upper-latin) '. ';
                }
            }
        }
    }


    // Offered/Requested
    .col-offered,
    .col-requested {
        ul {
            padding-left: 1.25rem;
        }

        p + ul {
            margin-top: -1.5rem;
        }
    }
}
