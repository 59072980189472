// Stories
.story-section {

    &.story-variant-1 {
        background-position-y: center;
        padding-bottom: 40px;

        @media all and (max-width: 1400px) {
            background-size: 1400px;
        }

        @include media-breakpoint-up(sm) {
            padding-bottom: 320px;
            margin-bottom: -260px;
            padding-top: 260px;
            margin-top: -260px;
        }

        @include media-breakpoint-up(md) {
            padding-bottom: 540px;
            margin-bottom: -460px;
            padding-top: 560px;
            margin-top: -460px;
        }

        .story-caption {
            .h1 {
                display: inline-block;
            }

            @include media-breakpoint-up(md) {
                &.text-md-right {
                    padding-right: 82px;
                }

                .story-caption__body,
                .story-caption__button,
                .story-caption__subtitle {
                    margin-right: 0.75rem;
                }
            }
        }

        .story-image {

        }
    }

    .position-relative {
        min-height: 340px;

        .story-caption {
            @media all and (min-width: 1800px) {
                position: absolute;
                right: 520px;
                top: 0;
            }
        }

        .story-image {
            position: static;

            @media all and (max-width: 767px) {
                padding-right: 0;
                padding-left: 0;

                img {
                    display: block;
                    margin: 0 -1rem -2.5rem -1rem;
                    width: calc(100% + 2rem);
                }
            }

            @media all and (min-width: 768px) {
                img {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    top: 0;

                    height: calc(100% + 180px);
                    width: auto;
                    margin-top: -100px;
                    margin-bottom: -80px;
                    max-width: 50vw;

                    object-fit: cover;
                    object-position: left top;
                }
            }

        }
    }
}

@media all and (min-width: 1640px) {
    .col-xxl-10 {
        max-width: 83.33333%;
        flex: 0 0 83.33333%;
    }
}
