.row-intro {
    position: relative;

    &:not(.row-intro--academy) {
        h1 {
            position: relative;

            .intro-icon {
                transform: translateY(-50%);
                margin-left: (-$spacer*2);
                margin-top: ($spacer/2);
                top: 50%; left: -20%;
                position: absolute;
                width: 20%;
            }
        }
    }

    &--academy {
        h1 {
            position: relative;

            .intro-icon {
                margin-bottom: 24px;
                display: block;

                @include media-breakpoint-down(md) {
                    width: 128px;
                }

                @include media-breakpoint-up(md) {
                    transform: translateY(-50%);
                    margin-left: (-$spacer*2);
                    margin-top: ($spacer/2);
                    top: 50%; left: -20%;
                    position: absolute;
                    margin-bottom: 0;
                    width: 25%;
                }

                @include media-breakpoint-up(lg) {
                    left: -30%;
                    width: 35%;
                }
            }
        }
    }
}
