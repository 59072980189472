// Slider media
.slider-media {

    .slick-prev {
        left: ($spacer*1.5);

        @include media-breakpoint-up(md) {
            left: ($spacer*3);
        }

        &::before {
            text-shadow: 0 0 16px rgba(0,0,0,0.25);
            content: "\e007"; // chevron-left
            color: $white;
        }
    }

    .slick-next {
        right: ($spacer*1.5);

        @include media-breakpoint-up(md) {
            right: ($spacer*3);
        }

        &::before {
            text-shadow: 0 0 16px rgba(0,0,0,0.25);
            content: "\e008"; // chevron-right
            color: $white;
        }
    }

    .slick-disabled {
        opacity: 0;
    }

    .item {
        position: relative;

        a {
            overflow: hidden;
            display: block;

            img {
                transition: 200ms transform ease-in;
                transform: scale(1);
            }

            .icon {
                transition: 200ms transform ease-in;
                transform: translate(-50%,-50%) scale(1);
            }

            &:hover {

                .icon {
                    transform: translate(-50%,-50%) scale(1.1);
                }
            }
        }

        .icon {
            text-shadow: 0 0 16px rgba(0,0,0,0.25);
            transform: translate(-50%,-50%);
            top: 50%; left: 50%;
            position: absolute;
            z-index: 2;
        }
    }
}
