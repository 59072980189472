@charset "UTF-8";
/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #5598cc;
  --secondary: #96ba20;
  --success: #96ba20;
  --info: #3b83c0;
  --warning: #ebb141;
  --danger: #d95c5c;
  --light: #f8f9fa;
  --dark: #343a40;
  --primary-light: #86c6ee;
  --secondary-light: #d3d800;
  --tertiary: #ec6707;
  --tertiary-light: #f39200;
  --default: #9d9c93;
  --white: #ffffff;
  --off-white: #f6f6f4;
  --font: #9d9c93;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "interstate", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "interstate", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #77766c;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: inherit;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #858378; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline, .footer-section .footer-menu {
  padding-left: 0;
  list-style: none; }

.list-inline-item, .list-inline li, .footer-section .footer-menu li {
  display: inline-block; }
  .list-inline-item:not(:last-child), .list-inline li:not(:last-child), .footer-section .footer-menu li:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote, article blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer, article blockquote footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before, article blockquote footer::before {
    content: "\2014\00A0"; }

.img-fluid, img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure, article figure {
  display: inline-block; }

.figure-img, article figure img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, article figure figcaption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, .slider-lazy:not(.slick-initialized) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #77766c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #77766c;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cfe2f1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a7c9e4; }

.table-hover .table-primary:hover {
  background-color: #bbd6eb; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bbd6eb; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e2ecc1; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c8db8b; }

.table-hover .table-secondary:hover {
  background-color: #d9e6ad; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d9e6ad; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #e2ecc1; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #c8db8b; }

.table-hover .table-success:hover {
  background-color: #d9e6ad; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #d9e6ad; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c8dced; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #99bfde; }

.table-hover .table-info:hover {
  background-color: #b5d0e7; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b5d0e7; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f9e9ca; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f5d69c; }

.table-hover .table-warning:hover {
  background-color: #f6dfb3; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #f6dfb3; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4d1d1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ebaaaa; }

.table-hover .table-danger:hover {
  background-color: #efbcbc; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #efbcbc; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #ddeffa; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #c0e1f6; }

.table-hover .table-primary-light:hover {
  background-color: #c7e5f7; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #c7e5f7; }

.table-secondary-light,
.table-secondary-light > th,
.table-secondary-light > td {
  background-color: #f3f4b8; }

.table-secondary-light th,
.table-secondary-light td,
.table-secondary-light thead th,
.table-secondary-light tbody + tbody {
  border-color: #e8eb7a; }

.table-hover .table-secondary-light:hover {
  background-color: #eff1a2; }
  .table-hover .table-secondary-light:hover > td,
  .table-hover .table-secondary-light:hover > th {
    background-color: #eff1a2; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #fad4ba; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #f5b07e; }

.table-hover .table-tertiary:hover {
  background-color: #f8c5a2; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #f8c5a2; }

.table-tertiary-light,
.table-tertiary-light > th,
.table-tertiary-light > td {
  background-color: #fce0b8; }

.table-tertiary-light th,
.table-tertiary-light td,
.table-tertiary-light thead th,
.table-tertiary-light tbody + tbody {
  border-color: #f9c67a; }

.table-hover .table-tertiary-light:hover {
  background-color: #fbd5a0; }
  .table-hover .table-tertiary-light:hover > td,
  .table-hover .table-tertiary-light:hover > th {
    background-color: #fbd5a0; }

.table-default,
.table-default > th,
.table-default > td {
  background-color: #e4e3e1; }

.table-default th,
.table-default td,
.table-default thead th,
.table-default tbody + tbody {
  border-color: #ccccc7; }

.table-hover .table-default:hover {
  background-color: #d8d6d4; }
  .table-hover .table-default:hover > td,
  .table-hover .table-default:hover > th {
    background-color: #d8d6d4; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-off-white,
.table-off-white > th,
.table-off-white > td {
  background-color: #fcfcfc; }

.table-off-white th,
.table-off-white td,
.table-off-white thead th,
.table-off-white tbody + tbody {
  border-color: #fafaf9; }

.table-hover .table-off-white:hover {
  background-color: #efefef; }
  .table-hover .table-off-white:hover > td,
  .table-hover .table-off-white:hover > th {
    background-color: #efefef; }

.table-font,
.table-font > th,
.table-font > td {
  background-color: #e4e3e1; }

.table-font th,
.table-font td,
.table-font thead th,
.table-font tbody + tbody {
  border-color: #ccccc7; }

.table-hover .table-font:hover {
  background-color: #d8d6d4; }
  .table-hover .table-font:hover > td,
  .table-hover .table-font:hover > th {
    background-color: #d8d6d4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #b5b5ae;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #77766c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #96ba20; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(150, 186, 32, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #96ba20;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2396ba20' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #96ba20;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #96ba20;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2396ba20' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #96ba20;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #96ba20; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #96ba20; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #96ba20; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #b4dc31;
  background-color: #b4dc31; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #96ba20; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #96ba20; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #96ba20;
  box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d95c5c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(217, 92, 92, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.error .form-control ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.error .form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid, .error .form-control {
  border-color: #d95c5c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d95c5c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d95c5c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .error .form-control:focus {
    border-color: #d95c5c;
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, .error textarea.form-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid, .error .custom-select.form-control {
  border-color: #d95c5c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d95c5c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d95c5c' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, .error .custom-select.form-control:focus {
    border-color: #d95c5c;
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .error .form-check-input.form-control ~ .form-check-label {
  color: #d95c5c; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .error .form-check-input.form-control ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.error .form-check-input.form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .error .custom-control-input.form-control ~ .custom-control-label {
  color: #d95c5c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .error .custom-control-input.form-control ~ .custom-control-label::before {
    border-color: #d95c5c; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .error .custom-control-input.form-control:checked ~ .custom-control-label::before {
  border-color: #e38585;
  background-color: #e38585; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .error .custom-control-input.form-control:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .error .custom-control-input.form-control:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d95c5c; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, .error .custom-file-input.form-control ~ .custom-file-label {
  border-color: #d95c5c; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, .error .custom-file-input.form-control:focus ~ .custom-file-label {
  border-color: #d95c5c;
  box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #77766c;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #77766c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #5598cc;
  border-color: #5598cc; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3a86c1;
    border-color: #377fb7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #3a86c1;
    border-color: #377fb7;
    box-shadow: 0 0 0 0.2rem rgba(111, 167, 212, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #377fb7;
    border-color: #3478ad; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 167, 212, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #96ba20;
  border-color: #96ba20; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19;
    box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #738e19;
    border-color: #6a8417; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }

.btn-success {
  color: #212529;
  background-color: #96ba20;
  border-color: #96ba20; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19;
    box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #738e19;
    border-color: #6a8417; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #3b83c0;
  border-color: #3b83c0; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #326fa3;
    border-color: #2f6899; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #326fa3;
    border-color: #2f6899;
    box-shadow: 0 0 0 0.2rem rgba(88, 150, 201, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #2f6899;
    border-color: #2c628f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 150, 201, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ebb141;
  border-color: #ebb141; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e7a31e;
    border-color: #e19d18; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e7a31e;
    border-color: #e19d18;
    box-shadow: 0 0 0 0.2rem rgba(205, 156, 61, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e19d18;
    border-color: #d69517; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(205, 156, 61, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #d95c5c;
  border-color: #d95c5c; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #d23d3d;
    border-color: #cf3333; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #d23d3d;
    border-color: #cf3333;
    box-shadow: 0 0 0 0.2rem rgba(223, 116, 116, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #cf3333;
    border-color: #c72e2e; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 116, 116, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-primary-light {
  color: #212529;
  background-color: #86c6ee;
  border-color: #86c6ee; }
  .btn-primary-light:hover {
    color: #212529;
    background-color: #64b6e9;
    border-color: #59b1e8; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #212529;
    background-color: #64b6e9;
    border-color: #59b1e8;
    box-shadow: 0 0 0 0.2rem rgba(119, 174, 208, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #59b1e8;
    border-color: #4eace6; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 174, 208, 0.5); }

.btn-secondary-light {
  color: #212529;
  background-color: #d3d800;
  border-color: #d3d800; }
  .btn-secondary-light:hover {
    color: #212529;
    background-color: #aeb200;
    border-color: #a1a500; }
  .btn-secondary-light:focus, .btn-secondary-light.focus {
    color: #212529;
    background-color: #aeb200;
    border-color: #a1a500;
    box-shadow: 0 0 0 0.2rem rgba(184, 189, 6, 0.5); }
  .btn-secondary-light.disabled, .btn-secondary-light:disabled {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
  .btn-secondary-light:not(:disabled):not(.disabled):active, .btn-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-light.dropdown-toggle {
    color: #ffffff;
    background-color: #a1a500;
    border-color: #959800; }
    .btn-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-secondary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(184, 189, 6, 0.5); }

.btn-tertiary {
  color: #ffffff;
  background-color: #ec6707;
  border-color: #ec6707; }
  .btn-tertiary:hover {
    color: #ffffff;
    background-color: #c75706;
    border-color: #ba5106; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    color: #ffffff;
    background-color: #c75706;
    border-color: #ba5106;
    box-shadow: 0 0 0 0.2rem rgba(239, 126, 44, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #ba5106;
    border-color: #ae4c05; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 126, 44, 0.5); }

.btn-tertiary-light {
  color: #212529;
  background-color: #f39200;
  border-color: #f39200; }
  .btn-tertiary-light:hover {
    color: #ffffff;
    background-color: #cd7b00;
    border-color: #c07300; }
  .btn-tertiary-light:focus, .btn-tertiary-light.focus {
    color: #ffffff;
    background-color: #cd7b00;
    border-color: #c07300;
    box-shadow: 0 0 0 0.2rem rgba(212, 130, 6, 0.5); }
  .btn-tertiary-light.disabled, .btn-tertiary-light:disabled {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
  .btn-tertiary-light:not(:disabled):not(.disabled):active, .btn-tertiary-light:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary-light.dropdown-toggle {
    color: #ffffff;
    background-color: #c07300;
    border-color: #b36c00; }
    .btn-tertiary-light:not(:disabled):not(.disabled):active:focus, .btn-tertiary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 130, 6, 0.5); }

.btn-default {
  color: #212529;
  background-color: #9d9c93;
  border-color: #9d9c93; }
  .btn-default:hover {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378; }
  .btn-default:focus, .btn-default.focus {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378;
    box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }
  .btn-default.disabled, .btn-default:disabled {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-default.dropdown-toggle {
    color: #ffffff;
    background-color: #858378;
    border-color: #7e7d72; }
    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }

.btn-white {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-off-white {
  color: #212529;
  background-color: #f6f6f4;
  border-color: #f6f6f4; }
  .btn-off-white:hover {
    color: #212529;
    background-color: #e5e5df;
    border-color: #dfdfd8; }
  .btn-off-white:focus, .btn-off-white.focus {
    color: #212529;
    background-color: #e5e5df;
    border-color: #dfdfd8;
    box-shadow: 0 0 0 0.2rem rgba(214, 215, 214, 0.5); }
  .btn-off-white.disabled, .btn-off-white:disabled {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
  .btn-off-white:not(:disabled):not(.disabled):active, .btn-off-white:not(:disabled):not(.disabled).active,
  .show > .btn-off-white.dropdown-toggle {
    color: #212529;
    background-color: #dfdfd8;
    border-color: #d9d9d1; }
    .btn-off-white:not(:disabled):not(.disabled):active:focus, .btn-off-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-off-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 215, 214, 0.5); }

.btn-font {
  color: #212529;
  background-color: #9d9c93;
  border-color: #9d9c93; }
  .btn-font:hover {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378; }
  .btn-font:focus, .btn-font.focus {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378;
    box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }
  .btn-font.disabled, .btn-font:disabled {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-font:not(:disabled):not(.disabled):active, .btn-font:not(:disabled):not(.disabled).active,
  .show > .btn-font.dropdown-toggle {
    color: #ffffff;
    background-color: #858378;
    border-color: #7e7d72; }
    .btn-font:not(:disabled):not(.disabled):active:focus, .btn-font:not(:disabled):not(.disabled).active:focus,
    .show > .btn-font.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }

.btn-outline-primary {
  color: #5598cc;
  border-color: #5598cc; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5598cc;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.5); }

.btn-outline-secondary {
  color: #96ba20;
  border-color: #96ba20; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #96ba20;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.btn-outline-success {
  color: #96ba20;
  border-color: #96ba20; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #96ba20;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.btn-outline-info {
  color: #3b83c0;
  border-color: #3b83c0; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 131, 192, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3b83c0;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 131, 192, 0.5); }

.btn-outline-warning {
  color: #ebb141;
  border-color: #ebb141; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 177, 65, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ebb141;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 177, 65, 0.5); }

.btn-outline-danger {
  color: #d95c5c;
  border-color: #d95c5c; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d95c5c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary-light {
  color: #86c6ee;
  border-color: #86c6ee; }
  .btn-outline-primary-light:hover {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 198, 238, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #86c6ee;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 198, 238, 0.5); }

.btn-outline-secondary-light {
  color: #d3d800;
  border-color: #d3d800; }
  .btn-outline-secondary-light:hover {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
  .btn-outline-secondary-light:focus, .btn-outline-secondary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 216, 0, 0.5); }
  .btn-outline-secondary-light.disabled, .btn-outline-secondary-light:disabled {
    color: #d3d800;
    background-color: transparent; }
  .btn-outline-secondary-light:not(:disabled):not(.disabled):active, .btn-outline-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-light.dropdown-toggle {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
    .btn-outline-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 216, 0, 0.5); }

.btn-outline-tertiary {
  color: #ec6707;
  border-color: #ec6707; }
  .btn-outline-tertiary:hover {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #ec6707;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.5); }

.btn-outline-tertiary-light {
  color: #f39200;
  border-color: #f39200; }
  .btn-outline-tertiary-light:hover {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
  .btn-outline-tertiary-light:focus, .btn-outline-tertiary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5); }
  .btn-outline-tertiary-light.disabled, .btn-outline-tertiary-light:disabled {
    color: #f39200;
    background-color: transparent; }
  .btn-outline-tertiary-light:not(:disabled):not(.disabled):active, .btn-outline-tertiary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary-light.dropdown-toggle {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
    .btn-outline-tertiary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5); }

.btn-outline-default {
  color: #9d9c93;
  border-color: #9d9c93; }
  .btn-outline-default:hover {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-outline-default:focus, .btn-outline-default.focus {
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }
  .btn-outline-default.disabled, .btn-outline-default:disabled {
    color: #9d9c93;
    background-color: transparent; }
  .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active,
  .show > .btn-outline-default.dropdown-toggle {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
    .btn-outline-default:not(:disabled):not(.disabled):active:focus, .btn-outline-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-off-white {
  color: #f6f6f4;
  border-color: #f6f6f4; }
  .btn-outline-off-white:hover {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
  .btn-outline-off-white:focus, .btn-outline-off-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 246, 244, 0.5); }
  .btn-outline-off-white.disabled, .btn-outline-off-white:disabled {
    color: #f6f6f4;
    background-color: transparent; }
  .btn-outline-off-white:not(:disabled):not(.disabled):active, .btn-outline-off-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-off-white.dropdown-toggle {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
    .btn-outline-off-white:not(:disabled):not(.disabled):active:focus, .btn-outline-off-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-off-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 246, 244, 0.5); }

.btn-outline-font {
  color: #9d9c93;
  border-color: #9d9c93; }
  .btn-outline-font:hover {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-outline-font:focus, .btn-outline-font.focus {
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }
  .btn-outline-font.disabled, .btn-outline-font:disabled {
    color: #9d9c93;
    background-color: transparent; }
  .btn-outline-font:not(:disabled):not(.disabled):active, .btn-outline-font:not(:disabled):not(.disabled).active,
  .show > .btn-outline-font.dropdown-toggle {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
    .btn-outline-font:not(:disabled):not(.disabled):active:focus, .btn-outline-font:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-font.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.btn-link {
  font-weight: 400;
  color: inherit;
  text-decoration: underline; }
  .btn-link:hover {
    color: inherit;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #77766c;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #5598cc; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #5598cc;
    background-color: #5598cc; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #b5b5ae; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #deebf5;
    border-color: #deebf5; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5598cc;
  background-color: #5598cc; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(85, 152, 204, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #b5b5ae;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #b5b5ae;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #deebf5; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #deebf5; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #deebf5; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #5598cc; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: 0 0 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 0 0; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.card-deck .card {
  margin-bottom: 1rem; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1rem;
    margin-left: -1rem; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 1rem;
      margin-bottom: 0;
      margin-left: 1rem; } }

.card-group > .card {
  margin-bottom: 1rem; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 2rem;
  list-style: none;
  background-color: none;
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #9d9c93;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #9d9c93; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: inherit;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: inherit;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #5598cc;
  border-color: #5598cc; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #5598cc; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #377fb7; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #96ba20; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #738e19; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.badge-success {
  color: #212529;
  background-color: #96ba20; }
  a.badge-success:hover, a.badge-success:focus {
    color: #212529;
    background-color: #738e19; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #3b83c0; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #2f6899; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(59, 131, 192, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ebb141; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #e19d18; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(235, 177, 65, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #d95c5c; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #cf3333; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.badge-primary-light {
  color: #212529;
  background-color: #86c6ee; }
  a.badge-primary-light:hover, a.badge-primary-light:focus {
    color: #212529;
    background-color: #59b1e8; }
  a.badge-primary-light:focus, a.badge-primary-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(134, 198, 238, 0.5); }

.badge-secondary-light {
  color: #212529;
  background-color: #d3d800; }
  a.badge-secondary-light:hover, a.badge-secondary-light:focus {
    color: #212529;
    background-color: #a1a500; }
  a.badge-secondary-light:focus, a.badge-secondary-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(211, 216, 0, 0.5); }

.badge-tertiary {
  color: #ffffff;
  background-color: #ec6707; }
  a.badge-tertiary:hover, a.badge-tertiary:focus {
    color: #ffffff;
    background-color: #ba5106; }
  a.badge-tertiary:focus, a.badge-tertiary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.5); }

.badge-tertiary-light {
  color: #212529;
  background-color: #f39200; }
  a.badge-tertiary-light:hover, a.badge-tertiary-light:focus {
    color: #212529;
    background-color: #c07300; }
  a.badge-tertiary-light:focus, a.badge-tertiary-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5); }

.badge-default {
  color: #212529;
  background-color: #9d9c93; }
  a.badge-default:hover, a.badge-default:focus {
    color: #212529;
    background-color: #858378; }
  a.badge-default:focus, a.badge-default.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.badge-white {
  color: #212529;
  background-color: #ffffff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-off-white {
  color: #212529;
  background-color: #f6f6f4; }
  a.badge-off-white:hover, a.badge-off-white:focus {
    color: #212529;
    background-color: #dfdfd8; }
  a.badge-off-white:focus, a.badge-off-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(246, 246, 244, 0.5); }

.badge-font {
  color: #212529;
  background-color: #9d9c93; }
  a.badge-font:hover, a.badge-font:focus {
    color: #212529;
    background-color: #858378; }
  a.badge-font:focus, a.badge-font.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2c4f6a;
  background-color: #ddeaf5;
  border-color: #cfe2f1; }
  .alert-primary hr {
    border-top-color: #bbd6eb; }
  .alert-primary .alert-link {
    color: #1d3446; }

.alert-secondary {
  color: #4e6111;
  background-color: #eaf1d2;
  border-color: #e2ecc1; }
  .alert-secondary hr {
    border-top-color: #d9e6ad; }
  .alert-secondary .alert-link {
    color: #2b3609; }

.alert-success {
  color: #4e6111;
  background-color: #eaf1d2;
  border-color: #e2ecc1; }
  .alert-success hr {
    border-top-color: #d9e6ad; }
  .alert-success .alert-link {
    color: #2b3609; }

.alert-info {
  color: #1f4464;
  background-color: #d8e6f2;
  border-color: #c8dced; }
  .alert-info hr {
    border-top-color: #b5d0e7; }
  .alert-info .alert-link {
    color: #132a3d; }

.alert-warning {
  color: #7a5c22;
  background-color: #fbefd9;
  border-color: #f9e9ca; }
  .alert-warning hr {
    border-top-color: #f6dfb3; }
  .alert-warning .alert-link {
    color: #523e17; }

.alert-danger {
  color: #713030;
  background-color: #f7dede;
  border-color: #f4d1d1; }
  .alert-danger hr {
    border-top-color: #efbcbc; }
  .alert-danger .alert-link {
    color: #4d2121; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-primary-light {
  color: #46677c;
  background-color: #e7f4fc;
  border-color: #ddeffa; }
  .alert-primary-light hr {
    border-top-color: #c7e5f7; }
  .alert-primary-light .alert-link {
    color: #344c5b; }

.alert-secondary-light {
  color: #6e7000;
  background-color: #f6f7cc;
  border-color: #f3f4b8; }
  .alert-secondary-light hr {
    border-top-color: #eff1a2; }
  .alert-secondary-light .alert-link {
    color: #3c3d00; }

.alert-tertiary {
  color: #7b3604;
  background-color: #fbe1cd;
  border-color: #fad4ba; }
  .alert-tertiary hr {
    border-top-color: #f8c5a2; }
  .alert-tertiary .alert-link {
    color: #4a2002; }

.alert-tertiary-light {
  color: #7e4c00;
  background-color: #fde9cc;
  border-color: #fce0b8; }
  .alert-tertiary-light hr {
    border-top-color: #fbd5a0; }
  .alert-tertiary-light .alert-link {
    color: #4b2d00; }

.alert-default {
  color: #52514c;
  background-color: #ebebe9;
  border-color: #e4e3e1; }
  .alert-default hr {
    border-top-color: #d8d6d4; }
  .alert-default .alert-link {
    color: #383733; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-off-white {
  color: #80807f;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-off-white hr {
    border-top-color: #efefef; }
  .alert-off-white .alert-link {
    color: #666666; }

.alert-font {
  color: #52514c;
  background-color: #ebebe9;
  border-color: #e4e3e1; }
  .alert-font hr {
    border-top-color: #d8d6d4; }
  .alert-font .alert-link {
    color: #383733; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #5598cc;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #77766c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #2c4f6a;
  background-color: #cfe2f1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2c4f6a;
    background-color: #bbd6eb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #2c4f6a;
    border-color: #2c4f6a; }

.list-group-item-secondary {
  color: #4e6111;
  background-color: #e2ecc1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4e6111;
    background-color: #d9e6ad; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e6111;
    border-color: #4e6111; }

.list-group-item-success {
  color: #4e6111;
  background-color: #e2ecc1; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #4e6111;
    background-color: #d9e6ad; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e6111;
    border-color: #4e6111; }

.list-group-item-info {
  color: #1f4464;
  background-color: #c8dced; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1f4464;
    background-color: #b5d0e7; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f4464;
    border-color: #1f4464; }

.list-group-item-warning {
  color: #7a5c22;
  background-color: #f9e9ca; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7a5c22;
    background-color: #f6dfb3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #7a5c22;
    border-color: #7a5c22; }

.list-group-item-danger {
  color: #713030;
  background-color: #f4d1d1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #713030;
    background-color: #efbcbc; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #713030;
    border-color: #713030; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-primary-light {
  color: #46677c;
  background-color: #ddeffa; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #46677c;
    background-color: #c7e5f7; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #46677c;
    border-color: #46677c; }

.list-group-item-secondary-light {
  color: #6e7000;
  background-color: #f3f4b8; }
  .list-group-item-secondary-light.list-group-item-action:hover, .list-group-item-secondary-light.list-group-item-action:focus {
    color: #6e7000;
    background-color: #eff1a2; }
  .list-group-item-secondary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #6e7000;
    border-color: #6e7000; }

.list-group-item-tertiary {
  color: #7b3604;
  background-color: #fad4ba; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #7b3604;
    background-color: #f8c5a2; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #ffffff;
    background-color: #7b3604;
    border-color: #7b3604; }

.list-group-item-tertiary-light {
  color: #7e4c00;
  background-color: #fce0b8; }
  .list-group-item-tertiary-light.list-group-item-action:hover, .list-group-item-tertiary-light.list-group-item-action:focus {
    color: #7e4c00;
    background-color: #fbd5a0; }
  .list-group-item-tertiary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7e4c00;
    border-color: #7e4c00; }

.list-group-item-default {
  color: #52514c;
  background-color: #e4e3e1; }
  .list-group-item-default.list-group-item-action:hover, .list-group-item-default.list-group-item-action:focus {
    color: #52514c;
    background-color: #d8d6d4; }
  .list-group-item-default.list-group-item-action.active {
    color: #ffffff;
    background-color: #52514c;
    border-color: #52514c; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-off-white {
  color: #80807f;
  background-color: #fcfcfc; }
  .list-group-item-off-white.list-group-item-action:hover, .list-group-item-off-white.list-group-item-action:focus {
    color: #80807f;
    background-color: #efefef; }
  .list-group-item-off-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #80807f;
    border-color: #80807f; }

.list-group-item-font {
  color: #52514c;
  background-color: #e4e3e1; }
  .list-group-item-font.list-group-item-action:hover, .list-group-item-font.list-group-item-action:focus {
    color: #52514c;
    background-color: #d8d6d4; }
  .list-group-item-font.list-group-item-action.active {
    color: #ffffff;
    background-color: #52514c;
    border-color: #52514c; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "interstate", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "interstate", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #858378;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #77766c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5598cc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #377fb7 !important; }

.bg-secondary {
  background-color: #96ba20 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #738e19 !important; }

.bg-success {
  background-color: #96ba20 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #738e19 !important; }

.bg-info {
  background-color: #3b83c0 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2f6899 !important; }

.bg-warning {
  background-color: #ebb141 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e19d18 !important; }

.bg-danger {
  background-color: #d95c5c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cf3333 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-primary-light {
  background-color: #86c6ee !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #59b1e8 !important; }

.bg-secondary-light {
  background-color: #d3d800 !important; }

a.bg-secondary-light:hover, a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: #a1a500 !important; }

.bg-tertiary {
  background-color: #ec6707 !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #ba5106 !important; }

.bg-tertiary-light {
  background-color: #f39200 !important; }

a.bg-tertiary-light:hover, a.bg-tertiary-light:focus,
button.bg-tertiary-light:hover,
button.bg-tertiary-light:focus {
  background-color: #c07300 !important; }

.bg-default {
  background-color: #9d9c93 !important; }

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #858378 !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-off-white {
  background-color: #f6f6f4 !important; }

a.bg-off-white:hover, a.bg-off-white:focus,
button.bg-off-white:hover,
button.bg-off-white:focus {
  background-color: #dfdfd8 !important; }

.bg-font {
  background-color: #9d9c93 !important; }

a.bg-font:hover, a.bg-font:focus,
button.bg-font:hover,
button.bg-font:focus {
  background-color: #858378 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5598cc !important; }

.border-secondary {
  border-color: #96ba20 !important; }

.border-success {
  border-color: #96ba20 !important; }

.border-info {
  border-color: #3b83c0 !important; }

.border-warning {
  border-color: #ebb141 !important; }

.border-danger {
  border-color: #d95c5c !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-primary-light {
  border-color: #86c6ee !important; }

.border-secondary-light {
  border-color: #d3d800 !important; }

.border-tertiary {
  border-color: #ec6707 !important; }

.border-tertiary-light {
  border-color: #f39200 !important; }

.border-default {
  border-color: #9d9c93 !important; }

.border-white {
  border-color: #ffffff !important; }

.border-off-white {
  border-color: #f6f6f4 !important; }

.border-font {
  border-color: #9d9c93 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .location-map::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item, .embed-responsive > *,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #5598cc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #3171a3 !important; }

.text-secondary {
  color: #96ba20 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #617915 !important; }

.text-success {
  color: #96ba20 !important; }

a.text-success:hover, a.text-success:focus {
  color: #617915 !important; }

.text-info {
  color: #3b83c0 !important; }

a.text-info:hover, a.text-info:focus {
  color: #295b85 !important; }

.text-warning {
  color: #ebb141 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ca8c15 !important; }

.text-danger {
  color: #d95c5c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2c2c !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-primary-light {
  color: #86c6ee !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #43a6e5 !important; }

.text-secondary-light {
  color: #d3d800 !important; }

a.text-secondary-light:hover, a.text-secondary-light:focus {
  color: #888c00 !important; }

.text-tertiary {
  color: #ec6707 !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #a24705 !important; }

.text-tertiary-light {
  color: #f39200 !important; }

a.text-tertiary-light:hover, a.text-tertiary-light:focus {
  color: #a76400 !important; }

.text-default {
  color: #9d9c93 !important; }

a.text-default:hover, a.text-default:focus {
  color: #77766c !important; }

.text-white {
  color: #ffffff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-off-white {
  color: #f6f6f4 !important; }

a.text-off-white:hover, a.text-off-white:focus {
  color: #d4d4ca !important; }

.text-font {
  color: #9d9c93 !important; }

a.text-font:hover, a.text-font:focus {
  color: #77766c !important; }

.text-body {
  color: #77766c !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #5598cc;
  --secondary: #96ba20;
  --success: #96ba20;
  --info: #3b83c0;
  --warning: #ebb141;
  --danger: #d95c5c;
  --light: #f8f9fa;
  --dark: #343a40;
  --primary-light: #86c6ee;
  --secondary-light: #d3d800;
  --tertiary: #ec6707;
  --tertiary-light: #f39200;
  --default: #9d9c93;
  --white: #ffffff;
  --off-white: #f6f6f4;
  --font: #9d9c93;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "interstate", sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5598cc !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #377fb7 !important; }

.bg-secondary {
  background-color: #96ba20 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #738e19 !important; }

.bg-success {
  background-color: #96ba20 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #738e19 !important; }

.bg-info {
  background-color: #3b83c0 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #2f6899 !important; }

.bg-warning {
  background-color: #ebb141 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #e19d18 !important; }

.bg-danger {
  background-color: #d95c5c !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #cf3333 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-primary-light {
  background-color: #86c6ee !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #59b1e8 !important; }

.bg-secondary-light {
  background-color: #d3d800 !important; }

a.bg-secondary-light:hover, a.bg-secondary-light:focus,
button.bg-secondary-light:hover,
button.bg-secondary-light:focus {
  background-color: #a1a500 !important; }

.bg-tertiary {
  background-color: #ec6707 !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #ba5106 !important; }

.bg-tertiary-light {
  background-color: #f39200 !important; }

a.bg-tertiary-light:hover, a.bg-tertiary-light:focus,
button.bg-tertiary-light:hover,
button.bg-tertiary-light:focus {
  background-color: #c07300 !important; }

.bg-default {
  background-color: #9d9c93 !important; }

a.bg-default:hover, a.bg-default:focus,
button.bg-default:hover,
button.bg-default:focus {
  background-color: #858378 !important; }

.bg-white {
  background-color: #ffffff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-off-white {
  background-color: #f6f6f4 !important; }

a.bg-off-white:hover, a.bg-off-white:focus,
button.bg-off-white:hover,
button.bg-off-white:focus {
  background-color: #dfdfd8 !important; }

.bg-font {
  background-color: #9d9c93 !important; }

a.bg-font:hover, a.bg-font:focus,
button.bg-font:hover,
button.bg-font:focus {
  background-color: #858378 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5598cc !important; }

.border-secondary {
  border-color: #96ba20 !important; }

.border-success {
  border-color: #96ba20 !important; }

.border-info {
  border-color: #3b83c0 !important; }

.border-warning {
  border-color: #ebb141 !important; }

.border-danger {
  border-color: #d95c5c !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-primary-light {
  border-color: #86c6ee !important; }

.border-secondary-light {
  border-color: #d3d800 !important; }

.border-tertiary {
  border-color: #ec6707 !important; }

.border-tertiary-light {
  border-color: #f39200 !important; }

.border-default {
  border-color: #9d9c93 !important; }

.border-white {
  border-color: #ffffff !important; }

.border-off-white {
  border-color: #f6f6f4 !important; }

.border-font {
  border-color: #9d9c93 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }

.rounded-right {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important; }

.rounded-bottom {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-left {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after, .location-map::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item, .embed-responsive > *,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #5598cc !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #3171a3 !important; }

.text-secondary {
  color: #96ba20 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #617915 !important; }

.text-success {
  color: #96ba20 !important; }

a.text-success:hover, a.text-success:focus {
  color: #617915 !important; }

.text-info {
  color: #3b83c0 !important; }

a.text-info:hover, a.text-info:focus {
  color: #295b85 !important; }

.text-warning {
  color: #ebb141 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ca8c15 !important; }

.text-danger {
  color: #d95c5c !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #bd2c2c !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-primary-light {
  color: #86c6ee !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #43a6e5 !important; }

.text-secondary-light {
  color: #d3d800 !important; }

a.text-secondary-light:hover, a.text-secondary-light:focus {
  color: #888c00 !important; }

.text-tertiary {
  color: #ec6707 !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #a24705 !important; }

.text-tertiary-light {
  color: #f39200 !important; }

a.text-tertiary-light:hover, a.text-tertiary-light:focus {
  color: #a76400 !important; }

.text-default {
  color: #9d9c93 !important; }

a.text-default:hover, a.text-default:focus {
  color: #77766c !important; }

.text-white {
  color: #ffffff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-off-white {
  color: #f6f6f4 !important; }

a.text-off-white:hover, a.text-off-white:focus {
  color: #d4d4ca !important; }

.text-font {
  color: #9d9c93 !important; }

a.text-font:hover, a.text-font:focus {
  color: #77766c !important; }

.text-body {
  color: #77766c !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 2rem;
  list-style: none;
  background-color: none;
  border-radius: 0; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #9d9c93;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #9d9c93; }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #77766c;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #77766c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #5598cc;
  border-color: #5598cc; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #3a86c1;
    border-color: #377fb7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #3a86c1;
    border-color: #377fb7;
    box-shadow: 0 0 0 0.2rem rgba(111, 167, 212, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #377fb7;
    border-color: #3478ad; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(111, 167, 212, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #96ba20;
  border-color: #96ba20; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19;
    box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #738e19;
    border-color: #6a8417; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }

.btn-success {
  color: #212529;
  background-color: #96ba20;
  border-color: #96ba20; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #7c991a;
    border-color: #738e19;
    box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #738e19;
    border-color: #6a8417; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 164, 33, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #3b83c0;
  border-color: #3b83c0; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #326fa3;
    border-color: #2f6899; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #326fa3;
    border-color: #2f6899;
    box-shadow: 0 0 0 0.2rem rgba(88, 150, 201, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #2f6899;
    border-color: #2c628f; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 150, 201, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ebb141;
  border-color: #ebb141; }
  .btn-warning:hover {
    color: #212529;
    background-color: #e7a31e;
    border-color: #e19d18; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #e7a31e;
    border-color: #e19d18;
    box-shadow: 0 0 0 0.2rem rgba(205, 156, 61, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #e19d18;
    border-color: #d69517; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(205, 156, 61, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #d95c5c;
  border-color: #d95c5c; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #d23d3d;
    border-color: #cf3333; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #d23d3d;
    border-color: #cf3333;
    box-shadow: 0 0 0 0.2rem rgba(223, 116, 116, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #cf3333;
    border-color: #c72e2e; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 116, 116, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #343a40;
  border-color: #343a40; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #23272b;
    border-color: #1d2124;
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1d2124;
    border-color: #171a1d; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-primary-light {
  color: #212529;
  background-color: #86c6ee;
  border-color: #86c6ee; }
  .btn-primary-light:hover {
    color: #212529;
    background-color: #64b6e9;
    border-color: #59b1e8; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #212529;
    background-color: #64b6e9;
    border-color: #59b1e8;
    box-shadow: 0 0 0 0.2rem rgba(119, 174, 208, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #59b1e8;
    border-color: #4eace6; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(119, 174, 208, 0.5); }

.btn-secondary-light {
  color: #212529;
  background-color: #d3d800;
  border-color: #d3d800; }
  .btn-secondary-light:hover {
    color: #212529;
    background-color: #aeb200;
    border-color: #a1a500; }
  .btn-secondary-light:focus, .btn-secondary-light.focus {
    color: #212529;
    background-color: #aeb200;
    border-color: #a1a500;
    box-shadow: 0 0 0 0.2rem rgba(184, 189, 6, 0.5); }
  .btn-secondary-light.disabled, .btn-secondary-light:disabled {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
  .btn-secondary-light:not(:disabled):not(.disabled):active, .btn-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-secondary-light.dropdown-toggle {
    color: #ffffff;
    background-color: #a1a500;
    border-color: #959800; }
    .btn-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-secondary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(184, 189, 6, 0.5); }

.btn-tertiary {
  color: #ffffff;
  background-color: #ec6707;
  border-color: #ec6707; }
  .btn-tertiary:hover {
    color: #ffffff;
    background-color: #c75706;
    border-color: #ba5106; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    color: #ffffff;
    background-color: #c75706;
    border-color: #ba5106;
    box-shadow: 0 0 0 0.2rem rgba(239, 126, 44, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #ba5106;
    border-color: #ae4c05; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(239, 126, 44, 0.5); }

.btn-tertiary-light {
  color: #212529;
  background-color: #f39200;
  border-color: #f39200; }
  .btn-tertiary-light:hover {
    color: #ffffff;
    background-color: #cd7b00;
    border-color: #c07300; }
  .btn-tertiary-light:focus, .btn-tertiary-light.focus {
    color: #ffffff;
    background-color: #cd7b00;
    border-color: #c07300;
    box-shadow: 0 0 0 0.2rem rgba(212, 130, 6, 0.5); }
  .btn-tertiary-light.disabled, .btn-tertiary-light:disabled {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
  .btn-tertiary-light:not(:disabled):not(.disabled):active, .btn-tertiary-light:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary-light.dropdown-toggle {
    color: #ffffff;
    background-color: #c07300;
    border-color: #b36c00; }
    .btn-tertiary-light:not(:disabled):not(.disabled):active:focus, .btn-tertiary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 130, 6, 0.5); }

.btn-default {
  color: #212529;
  background-color: #9d9c93;
  border-color: #9d9c93; }
  .btn-default:hover {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378; }
  .btn-default:focus, .btn-default.focus {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378;
    box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }
  .btn-default.disabled, .btn-default:disabled {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-default:not(:disabled):not(.disabled):active, .btn-default:not(:disabled):not(.disabled).active,
  .show > .btn-default.dropdown-toggle {
    color: #ffffff;
    background-color: #858378;
    border-color: #7e7d72; }
    .btn-default:not(:disabled):not(.disabled):active:focus, .btn-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }

.btn-white {
  color: #212529;
  background-color: #ffffff;
  border-color: #ffffff; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-off-white {
  color: #212529;
  background-color: #f6f6f4;
  border-color: #f6f6f4; }
  .btn-off-white:hover {
    color: #212529;
    background-color: #e5e5df;
    border-color: #dfdfd8; }
  .btn-off-white:focus, .btn-off-white.focus {
    color: #212529;
    background-color: #e5e5df;
    border-color: #dfdfd8;
    box-shadow: 0 0 0 0.2rem rgba(214, 215, 214, 0.5); }
  .btn-off-white.disabled, .btn-off-white:disabled {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
  .btn-off-white:not(:disabled):not(.disabled):active, .btn-off-white:not(:disabled):not(.disabled).active,
  .show > .btn-off-white.dropdown-toggle {
    color: #212529;
    background-color: #dfdfd8;
    border-color: #d9d9d1; }
    .btn-off-white:not(:disabled):not(.disabled):active:focus, .btn-off-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-off-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(214, 215, 214, 0.5); }

.btn-font {
  color: #212529;
  background-color: #9d9c93;
  border-color: #9d9c93; }
  .btn-font:hover {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378; }
  .btn-font:focus, .btn-font.focus {
    color: #ffffff;
    background-color: #8b8a7f;
    border-color: #858378;
    box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }
  .btn-font.disabled, .btn-font:disabled {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-font:not(:disabled):not(.disabled):active, .btn-font:not(:disabled):not(.disabled).active,
  .show > .btn-font.dropdown-toggle {
    color: #ffffff;
    background-color: #858378;
    border-color: #7e7d72; }
    .btn-font:not(:disabled):not(.disabled):active:focus, .btn-font:not(:disabled):not(.disabled).active:focus,
    .show > .btn-font.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(138, 138, 131, 0.5); }

.btn-outline-primary {
  color: #5598cc;
  border-color: #5598cc; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5598cc;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.5); }

.btn-outline-secondary {
  color: #96ba20;
  border-color: #96ba20; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #96ba20;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.btn-outline-success {
  color: #96ba20;
  border-color: #96ba20; }
  .btn-outline-success:hover {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #96ba20;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #212529;
    background-color: #96ba20;
    border-color: #96ba20; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.5); }

.btn-outline-info {
  color: #3b83c0;
  border-color: #3b83c0; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 131, 192, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3b83c0;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #3b83c0;
    border-color: #3b83c0; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(59, 131, 192, 0.5); }

.btn-outline-warning {
  color: #ebb141;
  border-color: #ebb141; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 177, 65, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ebb141;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ebb141;
    border-color: #ebb141; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 177, 65, 0.5); }

.btn-outline-danger {
  color: #d95c5c;
  border-color: #d95c5c; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #d95c5c;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #d95c5c;
    border-color: #d95c5c; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #343a40;
    border-color: #343a40; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-primary-light {
  color: #86c6ee;
  border-color: #86c6ee; }
  .btn-outline-primary-light:hover {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(134, 198, 238, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #86c6ee;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #212529;
    background-color: #86c6ee;
    border-color: #86c6ee; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(134, 198, 238, 0.5); }

.btn-outline-secondary-light {
  color: #d3d800;
  border-color: #d3d800; }
  .btn-outline-secondary-light:hover {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
  .btn-outline-secondary-light:focus, .btn-outline-secondary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 216, 0, 0.5); }
  .btn-outline-secondary-light.disabled, .btn-outline-secondary-light:disabled {
    color: #d3d800;
    background-color: transparent; }
  .btn-outline-secondary-light:not(:disabled):not(.disabled):active, .btn-outline-secondary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary-light.dropdown-toggle {
    color: #212529;
    background-color: #d3d800;
    border-color: #d3d800; }
    .btn-outline-secondary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(211, 216, 0, 0.5); }

.btn-outline-tertiary {
  color: #ec6707;
  border-color: #ec6707; }
  .btn-outline-tertiary:hover {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #ec6707;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #ffffff;
    background-color: #ec6707;
    border-color: #ec6707; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.5); }

.btn-outline-tertiary-light {
  color: #f39200;
  border-color: #f39200; }
  .btn-outline-tertiary-light:hover {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
  .btn-outline-tertiary-light:focus, .btn-outline-tertiary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5); }
  .btn-outline-tertiary-light.disabled, .btn-outline-tertiary-light:disabled {
    color: #f39200;
    background-color: transparent; }
  .btn-outline-tertiary-light:not(:disabled):not(.disabled):active, .btn-outline-tertiary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary-light.dropdown-toggle {
    color: #212529;
    background-color: #f39200;
    border-color: #f39200; }
    .btn-outline-tertiary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(243, 146, 0, 0.5); }

.btn-outline-default {
  color: #9d9c93;
  border-color: #9d9c93; }
  .btn-outline-default:hover {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-outline-default:focus, .btn-outline-default.focus {
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }
  .btn-outline-default.disabled, .btn-outline-default:disabled {
    color: #9d9c93;
    background-color: transparent; }
  .btn-outline-default:not(:disabled):not(.disabled):active, .btn-outline-default:not(:disabled):not(.disabled).active,
  .show > .btn-outline-default.dropdown-toggle {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
    .btn-outline-default:not(:disabled):not(.disabled):active:focus, .btn-outline-default:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-default.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.btn-outline-white {
  color: #ffffff;
  border-color: #ffffff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #ffffff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #ffffff;
    border-color: #ffffff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-off-white {
  color: #f6f6f4;
  border-color: #f6f6f4; }
  .btn-outline-off-white:hover {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
  .btn-outline-off-white:focus, .btn-outline-off-white.focus {
    box-shadow: 0 0 0 0.2rem rgba(246, 246, 244, 0.5); }
  .btn-outline-off-white.disabled, .btn-outline-off-white:disabled {
    color: #f6f6f4;
    background-color: transparent; }
  .btn-outline-off-white:not(:disabled):not(.disabled):active, .btn-outline-off-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-off-white.dropdown-toggle {
    color: #212529;
    background-color: #f6f6f4;
    border-color: #f6f6f4; }
    .btn-outline-off-white:not(:disabled):not(.disabled):active:focus, .btn-outline-off-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-off-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(246, 246, 244, 0.5); }

.btn-outline-font {
  color: #9d9c93;
  border-color: #9d9c93; }
  .btn-outline-font:hover {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
  .btn-outline-font:focus, .btn-outline-font.focus {
    box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }
  .btn-outline-font.disabled, .btn-outline-font:disabled {
    color: #9d9c93;
    background-color: transparent; }
  .btn-outline-font:not(:disabled):not(.disabled):active, .btn-outline-font:not(:disabled):not(.disabled).active,
  .show > .btn-outline-font.dropdown-toggle {
    color: #212529;
    background-color: #9d9c93;
    border-color: #9d9c93; }
    .btn-outline-font:not(:disabled):not(.disabled):active:focus, .btn-outline-font:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-font.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(157, 156, 147, 0.5); }

.btn-link {
  font-weight: 400;
  color: inherit;
  text-decoration: underline; }
  .btn-link:hover {
    color: inherit;
    text-decoration: none; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #5598cc;
    background-color: #5598cc; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #b5b5ae; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #deebf5;
    border-color: #deebf5; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5598cc;
  background-color: #5598cc; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(85, 152, 204, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(85, 152, 204, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #b5b5ae;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #b5b5ae;
    box-shadow: none; }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0 0 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #ffffff, none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #deebf5; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #deebf5; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5598cc;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #deebf5; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "interstate", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #77766c;
  text-align: left;
  background-color: #ffffff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1.5em; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: inherit;
  text-decoration: underline;
  background-color: transparent; }
  a:hover {
    color: inherit;
    text-decoration: none; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #858378; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline, .footer-section .footer-menu {
  padding-left: 0;
  list-style: none; }

.list-inline-item, .list-inline li, .footer-section .footer-menu li {
  display: inline-block; }
  .list-inline-item:not(:last-child), .list-inline li:not(:last-child), .footer-section .footer-menu li:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote, article blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer, article blockquote footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before, article blockquote footer::before {
    content: "\2014\00A0"; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2c4f6a;
  background-color: #ddeaf5;
  border-color: #cfe2f1; }
  .alert-primary hr {
    border-top-color: #bbd6eb; }
  .alert-primary .alert-link {
    color: #1d3446; }

.alert-secondary {
  color: #4e6111;
  background-color: #eaf1d2;
  border-color: #e2ecc1; }
  .alert-secondary hr {
    border-top-color: #d9e6ad; }
  .alert-secondary .alert-link {
    color: #2b3609; }

.alert-success {
  color: #4e6111;
  background-color: #eaf1d2;
  border-color: #e2ecc1; }
  .alert-success hr {
    border-top-color: #d9e6ad; }
  .alert-success .alert-link {
    color: #2b3609; }

.alert-info {
  color: #1f4464;
  background-color: #d8e6f2;
  border-color: #c8dced; }
  .alert-info hr {
    border-top-color: #b5d0e7; }
  .alert-info .alert-link {
    color: #132a3d; }

.alert-warning {
  color: #7a5c22;
  background-color: #fbefd9;
  border-color: #f9e9ca; }
  .alert-warning hr {
    border-top-color: #f6dfb3; }
  .alert-warning .alert-link {
    color: #523e17; }

.alert-danger {
  color: #713030;
  background-color: #f7dede;
  border-color: #f4d1d1; }
  .alert-danger hr {
    border-top-color: #efbcbc; }
  .alert-danger .alert-link {
    color: #4d2121; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

.alert-primary-light {
  color: #46677c;
  background-color: #e7f4fc;
  border-color: #ddeffa; }
  .alert-primary-light hr {
    border-top-color: #c7e5f7; }
  .alert-primary-light .alert-link {
    color: #344c5b; }

.alert-secondary-light {
  color: #6e7000;
  background-color: #f6f7cc;
  border-color: #f3f4b8; }
  .alert-secondary-light hr {
    border-top-color: #eff1a2; }
  .alert-secondary-light .alert-link {
    color: #3c3d00; }

.alert-tertiary {
  color: #7b3604;
  background-color: #fbe1cd;
  border-color: #fad4ba; }
  .alert-tertiary hr {
    border-top-color: #f8c5a2; }
  .alert-tertiary .alert-link {
    color: #4a2002; }

.alert-tertiary-light {
  color: #7e4c00;
  background-color: #fde9cc;
  border-color: #fce0b8; }
  .alert-tertiary-light hr {
    border-top-color: #fbd5a0; }
  .alert-tertiary-light .alert-link {
    color: #4b2d00; }

.alert-default {
  color: #52514c;
  background-color: #ebebe9;
  border-color: #e4e3e1; }
  .alert-default hr {
    border-top-color: #d8d6d4; }
  .alert-default .alert-link {
    color: #383733; }

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

.alert-off-white {
  color: #80807f;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-off-white hr {
    border-top-color: #efefef; }
  .alert-off-white .alert-link {
    color: #666666; }

.alert-font {
  color: #52514c;
  background-color: #ebebe9;
  border-color: #e4e3e1; }
  .alert-font hr {
    border-top-color: #d8d6d4; }
  .alert-font .alert-link {
    color: #383733; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #b5b5ae;
    outline: 0;
    box-shadow: none; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #77766c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #96ba20; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #212529;
  background-color: rgba(150, 186, 32, 0.9);
  border-radius: 0; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #96ba20;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2396ba20' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #96ba20;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #96ba20;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2396ba20' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #96ba20;
    box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #96ba20; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #96ba20; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #96ba20; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #b4dc31;
  background-color: #b4dc31; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #96ba20; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #96ba20; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #96ba20;
  box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #d95c5c; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #ffffff;
  background-color: rgba(217, 92, 92, 0.9);
  border-radius: 0; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.error .form-control ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip,
.error .form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid, .error .form-control {
  border-color: #d95c5c;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d95c5c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d95c5c' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .error .form-control:focus {
    border-color: #d95c5c;
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid, .error textarea.form-control {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid, .error .custom-select.form-control {
  border-color: #d95c5c;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d95c5c' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d95c5c' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, .error .custom-select.form-control:focus {
    border-color: #d95c5c;
    box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, .error .form-check-input.form-control ~ .form-check-label {
  color: #d95c5c; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback, .error .form-check-input.form-control ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip,
.error .form-check-input.form-control ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, .error .custom-control-input.form-control ~ .custom-control-label {
  color: #d95c5c; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, .error .custom-control-input.form-control ~ .custom-control-label::before {
    border-color: #d95c5c; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, .error .custom-control-input.form-control:checked ~ .custom-control-label::before {
  border-color: #e38585;
  background-color: #e38585; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, .error .custom-control-input.form-control:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .error .custom-control-input.form-control:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d95c5c; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, .error .custom-file-input.form-control ~ .custom-file-label {
  border-color: #d95c5c; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, .error .custom-file-input.form-control:focus ~ .custom-file-label {
  border-color: #d95c5c;
  box-shadow: 0 0 0 0.2rem rgba(217, 92, 92, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.img-fluid, img {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 0;
  max-width: 100%;
  height: auto; }

.figure, article figure {
  display: inline-block; }

.figure-img, article figure img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption, article figure figcaption {
  font-size: 90%;
  color: #6c757d; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #77766c;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  text-decoration: none;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #5598cc;
    border-color: #5598cc; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #2c4f6a;
  background-color: #cfe2f1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2c4f6a;
    background-color: #bbd6eb; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #2c4f6a;
    border-color: #2c4f6a; }

.list-group-item-secondary {
  color: #4e6111;
  background-color: #e2ecc1; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #4e6111;
    background-color: #d9e6ad; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e6111;
    border-color: #4e6111; }

.list-group-item-success {
  color: #4e6111;
  background-color: #e2ecc1; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #4e6111;
    background-color: #d9e6ad; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #4e6111;
    border-color: #4e6111; }

.list-group-item-info {
  color: #1f4464;
  background-color: #c8dced; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1f4464;
    background-color: #b5d0e7; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #1f4464;
    border-color: #1f4464; }

.list-group-item-warning {
  color: #7a5c22;
  background-color: #f9e9ca; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #7a5c22;
    background-color: #f6dfb3; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #7a5c22;
    border-color: #7a5c22; }

.list-group-item-danger {
  color: #713030;
  background-color: #f4d1d1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #713030;
    background-color: #efbcbc; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #713030;
    border-color: #713030; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.list-group-item-primary-light {
  color: #46677c;
  background-color: #ddeffa; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #46677c;
    background-color: #c7e5f7; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #46677c;
    border-color: #46677c; }

.list-group-item-secondary-light {
  color: #6e7000;
  background-color: #f3f4b8; }
  .list-group-item-secondary-light.list-group-item-action:hover, .list-group-item-secondary-light.list-group-item-action:focus {
    color: #6e7000;
    background-color: #eff1a2; }
  .list-group-item-secondary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #6e7000;
    border-color: #6e7000; }

.list-group-item-tertiary {
  color: #7b3604;
  background-color: #fad4ba; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #7b3604;
    background-color: #f8c5a2; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #ffffff;
    background-color: #7b3604;
    border-color: #7b3604; }

.list-group-item-tertiary-light {
  color: #7e4c00;
  background-color: #fce0b8; }
  .list-group-item-tertiary-light.list-group-item-action:hover, .list-group-item-tertiary-light.list-group-item-action:focus {
    color: #7e4c00;
    background-color: #fbd5a0; }
  .list-group-item-tertiary-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #7e4c00;
    border-color: #7e4c00; }

.list-group-item-default {
  color: #52514c;
  background-color: #e4e3e1; }
  .list-group-item-default.list-group-item-action:hover, .list-group-item-default.list-group-item-action:focus {
    color: #52514c;
    background-color: #d8d6d4; }
  .list-group-item-default.list-group-item-action.active {
    color: #ffffff;
    background-color: #52514c;
    border-color: #52514c; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-off-white {
  color: #80807f;
  background-color: #fcfcfc; }
  .list-group-item-off-white.list-group-item-action:hover, .list-group-item-off-white.list-group-item-action:focus {
    color: #80807f;
    background-color: #efefef; }
  .list-group-item-off-white.list-group-item-action.active {
    color: #ffffff;
    background-color: #80807f;
    border-color: #80807f; }

.list-group-item-font {
  color: #52514c;
  background-color: #e4e3e1; }
  .list-group-item-font.list-group-item-action:hover, .list-group-item-font.list-group-item-action:focus {
    color: #52514c;
    background-color: #d8d6d4; }
  .list-group-item-font.list-group-item-action.active {
    color: #ffffff;
    background-color: #52514c;
    border-color: #52514c; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row, .slider-lazy:not(.slick-initialized) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@font-face {
  font-family: "base";
  src: url("fonts/base.eot");
  src: url("fonts/base.eot?#iefix") format("embedded-opentype"), url("fonts/base.woff") format("woff"), url("fonts/base.ttf") format("truetype"), url("fonts/base.svg#base") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal; }

[data-icon]:before {
  font-family: "base" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "base" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-slick-next:before {
  content: "\e000"; }

.icon-slick-previous:before {
  content: "\e001"; }

.icon-slick-dot:before {
  content: "\e002"; }

.icon-slick-refresh:before {
  content: "\e003"; }

.icon-direction:before {
  content: "\e004"; }

.icon-menu:before {
  content: "\e005"; }

.icon-chevron-down:before {
  content: "\e006"; }

.icon-chevron-left:before {
  content: "\e007"; }

.icon-chevron-right:before {
  content: "\e008"; }

.icon-chevron-small-down:before {
  content: "\e009"; }

.icon-chevron-small-right-1:before {
  content: "\e00a"; }

.icon-chevron-small-right:before {
  content: "\e00b"; }

.icon-chevron-small-up:before {
  content: "\e00c"; }

.icon-chevron-with-circle-down:before {
  content: "\e00d"; }

.icon-chevron-with-circle-left:before {
  content: "\e00e"; }

.icon-chevron-with-circle-right:before {
  content: "\e00f"; }

.icon-chevron-with-circle-up:before {
  content: "\e010"; }

.icon-compass:before {
  content: "\e011"; }

.icon-landline:before {
  content: "\e012"; }

.icon-mail:before {
  content: "\e014"; }

.icon-facebook-2:before {
  content: "\e015"; }

.icon-youtube-with-circle:before {
  content: "\e017"; }

.icon-twitter:before {
  content: "\e018"; }

.icon-twitter-with-circle:before {
  content: "\e019"; }

.icon-pinterest:before {
  content: "\e01a"; }

.icon-pinterest-with-circle:before {
  content: "\e01b"; }

.icon-linkedin:before {
  content: "\e01c"; }

.icon-linkedin-with-circle:before {
  content: "\e01d"; }

.icon-google:before {
  content: "\e01e"; }

.icon-google-with-circle:before {
  content: "\e01f"; }

.icon-facebook-with-circle:before {
  content: "\e020"; }

.icon-search:before {
  content: "\e021"; }

.icon-plus:before {
  content: "\e023"; }

.icon-check:before {
  content: "\e024"; }

.icon-share:before {
  content: "\e025"; }

.icon-minus:before {
  content: "\e026"; }

.icon-squared-cross:before {
  content: "\e027"; }

.icon-squared-minus:before {
  content: "\e028"; }

.icon-squared-plus:before {
  content: "\e029"; }

.icon-warning:before {
  content: "\e02a"; }

.icon-users:before {
  content: "\e02d"; }

.icon-circle-with-cross:before {
  content: "\e02e"; }

.icon-circle-with-minus:before {
  content: "\e02f"; }

.icon-user:before {
  content: "\e031"; }

.icon-shopping-cart:before {
  content: "\e032"; }

.icon-shopping-basket:before {
  content: "\e033"; }

.icon-shopping-bag:before {
  content: "\e034"; }

.icon-old-phone:before {
  content: "\e035"; }

.icon-location-pin:before {
  content: "\e036"; }

.icon-info-with-circle:before {
  content: "\e037"; }

.icon-circle-with-plus:before {
  content: "\e030"; }

.icon-vimeo:before {
  content: "\e038"; }

.icon-vimeo-with-circle:before {
  content: "\e039"; }

.icon-attachment:before {
  content: "\e03b"; }

.icon-text-document:before {
  content: "\e03a"; }

.icon-text-document-inverted:before {
  content: "\e03c"; }

.icon-foursquare:before {
  content: "\e03d"; }

.icon-instagram:before {
  content: "\e03f"; }

.icon-instagram-with-circle:before {
  content: "\e040"; }

.icon-tumblr-with-circle:before {
  content: "\e041"; }

.icon-tumblr:before {
  content: "\e042"; }

.icon-star:before {
  content: "\e043"; }

.icon-print:before {
  content: "\e046"; }

.icon-heart:before {
  content: "\e047"; }

.icon-bar-graph:before {
  content: "\e048"; }

.icon-calendar:before {
  content: "\e049"; }

.icon-pencil:before {
  content: "\e04a"; }

.icon-controller-play:before {
  content: "\e04b"; }

.icon-triangle-down:before {
  content: "\e04c"; }

.icon-triangle-left:before {
  content: "\e04d"; }

.icon-triangle-right:before {
  content: "\e04e"; }

.icon-triangle-up:before {
  content: "\e04f"; }

.icon-thumbs-down:before {
  content: "\e050"; }

.icon-thumbs-up:before {
  content: "\e051"; }

.icon-star-outlined:before {
  content: "\e052"; }

.icon-tag:before {
  content: "\e053"; }

.icon-select-arrows:before {
  content: "\e054"; }

.icon-resize-100:before {
  content: "\e055"; }

.icon-resize-full-screen:before {
  content: "\e056"; }

.icon-heart-outlined:before {
  content: "\e057"; }

.icon-help:before {
  content: "\e058"; }

.icon-help-with-circle:before {
  content: "\e059"; }

.icon-home:before {
  content: "\e05a"; }

.icon-image:before {
  content: "\e05b"; }

.icon-info:before {
  content: "\e05c"; }

.icon-edit:before {
  content: "\e05d"; }

.icon-flag:before {
  content: "\e05e"; }

.icon-eye:before {
  content: "\e05f"; }

.icon-eye-with-line:before {
  content: "\e060"; }

.icon-chevron-thin-down:before {
  content: "\e061"; }

.icon-chevron-thin-left:before {
  content: "\e062"; }

.icon-chevron-thin-right:before {
  content: "\e063"; }

.icon-chevron-thin-up:before {
  content: "\e064"; }

.icon-chevron-up:before {
  content: "\e065"; }

.icon-cw:before {
  content: "\e066"; }

.icon-cycle:before {
  content: "\e067"; }

.icon-controller-paus:before {
  content: "\e068"; }

.icon-clock:before {
  content: "\e069"; }

.icon-code:before {
  content: "\e06a"; }

.icon-chat:before {
  content: "\e06b"; }

.icon-beamed-note:before {
  content: "\e06c"; }

.icon-credit-card:before {
  content: "\e06d"; }

.icon-export:before {
  content: "\e06e"; }

.icon-flash:before {
  content: "\e06f"; }

.icon-globe:before {
  content: "\e070"; }

.icon-map:before {
  content: "\e071"; }

.icon-lock:before {
  content: "\e072"; }

.icon-lock-open:before {
  content: "\e073"; }

.icon-message:before {
  content: "\e074"; }

.icon-price-tag:before {
  content: "\e075"; }

.icon-save:before {
  content: "\e076"; }

.icon-retweet:before {
  content: "\e077"; }

.icon-bell:before {
  content: "\e078"; }

.icon-add-user:before {
  content: "\e079"; }

.icon-remove-user:before {
  content: "\e07a"; }

.icon-rocket:before {
  content: "\e07b"; }

.icon-rss:before {
  content: "\e07c"; }

.icon-pie-chart:before {
  content: "\e07d"; }

.icon-pin:before {
  content: "\e07e"; }

.icon-overview:before {
  content: "\e02c"; }

.icon-flickr-with-circle:before {
  content: "\e03e"; }

.icon-arrow-circle-o-down:before {
  content: "\e045"; }

.icon-login:before {
  content: "\e07f"; }

.icon-trash:before {
  content: "\e087"; }

.icon-mobile:before {
  content: "\e088"; }

.icon-facebook:before {
  content: "\e02b"; }

.icon-file-pdf-o:before {
  content: "\e08a"; }

.icon-file-excel-o:before {
  content: "\e016"; }

.icon-youtube:before {
  content: "\e083"; }

.icon-file-image-o:before {
  content: "\e089"; }

.icon-file-powerpoint-o:before {
  content: "\e08b"; }

.icon-file-word-o:before {
  content: "\e08c"; }

.icon-file-video-o:before {
  content: "\e08d"; }

.icon-file-code-o:before {
  content: "\e08e"; }

.icon-file-audio-o:before {
  content: "\e08f"; }

.icon-file-archive-o:before {
  content: "\e090"; }

.icon-file-o:before {
  content: "\e092"; }

.icon-file-text-o:before {
  content: "\e091"; }

.icon-play-circle-o:before {
  content: "\e093"; }

.icon-cart-plus:before {
  content: "\e095"; }

.icon-linkedin-2:before {
  content: "\e096"; }

.icon-facebook-4:before {
  content: "\e097"; }

.icon-quote-right:before {
  content: "\e098"; }

.icon-layers:before {
  content: "\e099"; }

.icon-sound-mix:before {
  content: "\e09a"; }

.icon-tag-1:before {
  content: "\e09b"; }

.icon-drop:before {
  content: "\e09d"; }

.icon-funnel:before {
  content: "\e09e"; }

.icon-new-message:before {
  content: "\e09c"; }

.icon-location:before {
  content: "\e09f"; }

.icon-stopwatch:before {
  content: "\e0a0"; }

.icon-camera:before {
  content: "\e0a1"; }

.icon-briefcase:before {
  content: "\e0a2"; }

.icon-key:before {
  content: "\e0a3"; }

.icon-inbox:before {
  content: "\e0a4"; }

.icon-clipboard:before {
  content: "\e0a5"; }

.icon-block:before {
  content: "\e0a7"; }

.icon-truck:before {
  content: "\e0a6"; }

.icon-list-rows:before {
  content: "\e0aa"; }

.icon-list-grid:before {
  content: "\e0ab"; }

.icon-envelope-o:before {
  content: "\e044"; }

.icon-whatsapp:before {
  content: "\e013"; }

.icon-phone-left:before {
  content: "\e081"; }

.icon-phone:before {
  content: "\e022"; }

.icon-twitter-square:before {
  content: "\e080"; }

.icon-phone-ringing:before {
  content: "\e082"; }

.icon-cross:before {
  content: "\e084"; }

.icon-folder:before {
  content: "\e085"; }

.icon-folder-open:before {
  content: "\e086"; }

.icon-arrow-right-c:before {
  content: "\e0a8"; }

.icon-phone-call:before {
  content: "\e0a9"; }

.icon-mail-1:before {
  content: "\e0ac"; }

.icon-graduation-cap:before {
  content: "\e094"; }

.icon-clock-1:before {
  content: "\e0ad"; }

.icon-check-1:before {
  content: "\e0ae"; }

.icon-play-circle:before {
  content: "\e0af"; }

.icon-arrow-left-c:before {
  content: "\e0b0"; }

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Fira Sans Regular"), local("FiraSans-Regular"), url(https://fonts.gstatic.com/s/firasans/v7/MIPWVWI_mY_QERxcMVPEwBJtnKITppOI_IvcXXDNrsc.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Fira Sans Regular"), local("FiraSans-Regular"), url(https://fonts.gstatic.com/s/firasans/v7/EjsrzDkQUQCDwsBtLpcVQVtXRa8TVwTICgirnJhmVJw.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: local("Fira Sans Medium"), local("FiraSans-Medium"), url(https://fonts.gstatic.com/s/firasans/v7/zM2u8V3CuPVwAAXFQcDi4D0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 500;
  src: local("Fira Sans Medium"), local("FiraSans-Medium"), url(https://fonts.gstatic.com/s/firasans/v7/zM2u8V3CuPVwAAXFQcDi4Ogdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

/* latin-ext */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Fira Sans Medium"), local("FiraSans-Medium"), url(https://fonts.gstatic.com/s/firasans/v7/zM2u8V3CuPVwAAXFQcDi4D0LW-43aMEzIO6XUTLjad8.woff2) format("woff2");
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: 'Fira Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Fira Sans Medium"), local("FiraSans-Medium"), url(https://fonts.gstatic.com/s/firasans/v7/zM2u8V3CuPVwAAXFQcDi4Ogdm0LZdjqr5-oayXSOefg.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215; }

.dev {
  outline: 1px dashed red; }

html {
  background-color: #f6f6f4;
  min-height: 100vh; }

body {
  padding-top: 80px; }

.site-wrapper {
  overflow: hidden;
  width: 100%; }

.col-max-sm,
.col-max-md,
.col-max-lg {
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%; }

.col-max-sm {
  max-width: 576px; }

.col-max-md {
  max-width: 768px; }

.col-max-lg {
  max-width: 992px; }

.reset-col-md-8 {
  margin-left: calc(-25% - ((100vw - 150%) / 2));
  min-width: 100vw; }

.line {
  display: inline-block; }

h1, .h1 {
  font-size: 1.5rem;
  color: #9d9c93; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 2rem; } }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 2.5rem; } }

.display-1 {
  line-height: 1.1em;
  font-size: 2rem;
  color: #9d9c93; }
  @media (min-width: 576px) {
    .display-1 {
      font-size: 4rem; } }
  @media (min-width: 768px) {
    .display-1 {
      font-size: 5rem; } }
  @media (min-width: 992px) {
    .display-1 {
      font-size: 6rem; } }

h2, .h2 {
  font-size: 1.3rem;
  color: #9d9c93; }
  @media (min-width: 576px) {
    h2, .h2 {
      font-size: 1.5rem; } }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  color: #9d9c93; }

h4, .h4 {
  font-weight: 500;
  color: #9d9c93; }

h5, .h5 {
  font-weight: 500;
  color: #9d9c93; }
  article h5, article .h5 {
    line-height: inherit;
    font-size: inherit;
    margin-bottom: 0;
    font-weight: 700; }

h6, .h6 {
  color: #9d9c93; }

b,
strong {
  color: #9d9c93; }

.text-500 {
  font-weight: 500; }

.text-700 {
  font-weight: 700; }

.link-inverted, .footer-section .footer-menu a, .menu-section .language-select a {
  text-decoration: none; }
  .link-inverted:hover, .footer-section .footer-menu a:hover, .menu-section .language-select a:hover,
  a:hover .link-inverted,
  a:hover .footer-section .footer-menu a,
  .footer-section .footer-menu a:hover a,
  a:hover .menu-section .language-select a,
  .menu-section .language-select a:hover a {
    text-decoration: underline; }

[data-toggle] {
  user-select: none;
  cursor: pointer; }

.icon {
  vertical-align: middle;
  margin-bottom: -0.2rem;
  display: inline-block;
  margin-top: -0.2rem; }
  .icon:not(:only-child):first-child {
    margin-right: 0.3rem; }

.svg-icon {
  vertical-align: middle;
  margin-bottom: -0.2rem;
  display: inline-flex;
  margin-top: -0.2rem;
  height: 1em;
  width: 1em; }
  .svg-icon:not(:only-child):first-child {
    margin-right: 0.3rem; }
  .svg-icon.svg-icon-small {
    height: 0.85rem;
    width: 0.85rem; }

.btn {
  transition: 200ms all ease-in-out;
  padding: .425rem 1.5rem .375rem;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.2;
  font-size: 1rem; }
  .btn-success {
    color: #ffffff; }
  .btn-outline-white:hover {
    color: #9d9c93; }
  .btn-outline-default {
    background: #ffffff; }
    .btn-outline-default:hover {
      color: #ffffff !important; }
  .btn:not(.stretched-link):hover {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    transform: scale(1.05); }
  .btn.btn-default, .btn.btn-primary, .btn.btn-primary-light, .btn.btn-secondary, .btn.btn-secondary-light, .btn.btn-tertiary, .btn.btn-tertiary-light {
    color: #ffffff; }
  .body_theme-color-primary .btn.btn-theme {
    background-color: #86c6ee;
    color: #ffffff; }
  .body_theme-color-secondary .btn.btn-theme {
    background-color: #d3d800;
    color: #ffffff; }
  .body_theme-color-tertiary .btn.btn-theme {
    background-color: #f39200;
    color: #ffffff; }

/* Form styling */
span.req {
  color: #d95c5c; }

textarea.form-control {
  min-height: 8.6rem; }

.custom-control-label::after, .custom-control-label::before {
  top: 0.3em; }

label[for] {
  cursor: pointer; }

.mb-lc-0 > :last-child, .alert > :last-child, .error-content > :last-child {
  margin-bottom: 0 !important; }

.bg-light .card {
  background: none; }

.embed-responsive {
  position: relative;
  cursor: pointer; }
  .embed-responsive img {
    display: none; }
  .embed-responsive iframe[data-src] {
    background-color: rgba(255, 255, 255, 0.5);
    pointer-events: none; }
    .embed-responsive iframe[data-src] + img {
      pointer-events: none;
      position: absolute;
      object-fit: cover;
      top: 0;
      left: 0;
      display: block;
      height: 100%;
      width: 100%; }

[data-toggle-target] {
  user-select: none;
  cursor: pointer; }

img.lazyload, img.lazyloading {
  transition: 300ms opacity;
  opacity: 0; }

img.lazyloaded {
  transition: 300ms opacity;
  opacity: 1; }

.slider-media[class^="slider"] .slick-prev,
.slider-media[class^="slider"] .slick-next {
  transition: 200ms box-shadow ease-in;
  background-color: #ffffff;
  border-radius: 50%;
  height: 2rem;
  width: 2rem; }
  @media (min-width: 576px) {
    .slider-media[class^="slider"] .slick-prev,
    .slider-media[class^="slider"] .slick-next {
      height: 3rem;
      width: 3rem; } }
  .slider-media[class^="slider"] .slick-prev:hover,
  .slider-media[class^="slider"] .slick-next:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }
  .slider-media[class^="slider"] .slick-prev::before,
  .slider-media[class^="slider"] .slick-next::before {
    color: #9d9c93 !important; }

.slider-media[class^="slider"] .slick-prev {
  left: 3rem; }
  @media (min-width: 576px) {
    .slider-media[class^="slider"] .slick-prev {
      left: 4rem; } }

.slider-media[class^="slider"] .slick-next {
  right: 3rem; }
  @media (min-width: 576px) {
    .slider-media[class^="slider"] .slick-next {
      right: 4rem; } }

.location-map-info p:not(:last-child) {
  margin-bottom: 0.5rem; }

@media all and (min-width: 399px) {
  .col-xs-6 {
    max-width: 50%;
    flex: 0 0 50%; } }

[href="#"]:not([id]) {
  outline: 2px dashed red; }

img.icon,
div.icon {
  display: inline-block !important;
  vertical-align: middle;
  height: 1.5rem; }

img.icon-2x,
div.icon-2x {
  display: inline-block !important;
  vertical-align: middle;
  height: 2rem; }

img.icon-3x,
div.icon-3x {
  display: inline-block !important;
  vertical-align: middle;
  height: 3rem; }

.bg-default, .bg-primary, .bg-primary-light, .bg-secondary, .bg-secondary-light, .bg-tertiary, .bg-tertiary-light {
  color: #ffffff; }
  .bg-default *, .bg-primary *, .bg-primary-light *, .bg-secondary *, .bg-secondary-light *, .bg-tertiary *, .bg-tertiary-light * {
    color: inherit; }

.bg-primary .btn-outline-white:hover {
  color: #5598cc; }

.bg-primary-light .btn-outline-white:hover {
  color: #86c6ee; }

.bg-secondary .btn-outline-white:hover {
  color: #96ba20; }

.bg-secondary-light .btn-outline-white:hover {
  color: #d3d800; }

.bg-tertiary .btn-outline-white:hover {
  color: #ec6707; }

.bg-tertiary-light .btn-outline-white:hover {
  color: #f39200; }

.bg-lines-1 {
  background-image: url("../../frontend/images/bg/lines-1.svg"); }

.bg-lines-2 {
  background-image: url("../../frontend/images/bg/lines-2.svg"); }

.bg-lines-3 {
  background-image: url("../../frontend/images/bg/lines-3.svg"); }

.bg-lines-4 {
  background-image: url("../../frontend/images/bg/lines-4.svg"); }

.bg-lines-5 {
  background-image: url("../../frontend/images/bg/lines-5.svg"); }

.bg-lines-6 {
  background-image: url("../../frontend/images/bg/lines-6.svg"); }

[class*="bg-lines-"] {
  background-position: center 50px;
  background-repeat: no-repeat;
  background-size: 100vw; }

.text-sm {
  font-size: 0.85em; }

.text-medium {
  font-size: 1rem !important; }

.text-lg {
  font-size: 1.25em; }

.text-xl {
  font-size: 1.5em; }

.text-2x {
  font-size: 2em; }

.text-3x {
  line-height: 1em;
  font-size: 3em; }

.text-4x {
  line-height: 1em;
  font-size: 4em; }

@media (min-width: 992px) {
  .text-lg-sm {
    font-size: 0.85em; }
  .text-lg-lg {
    font-size: 1.25em; }
  .text-lg-xl {
    font-size: 1.5em; }
  .text-lg-2x {
    font-size: 2em; }
  .text-lg-3x {
    line-height: 1em;
    font-size: 3em; }
  .text-lg-4x {
    line-height: 1em;
    font-size: 4em; } }

@media (min-width: 1200px) {
  .text-xl-sm {
    font-size: 0.85em; }
  .text-xl-lg {
    font-size: 1.25em; }
  .text-xl-xl {
    font-size: 1.5em; }
  .text-xl-2x {
    font-size: 2em; }
  .text-xl-3x {
    line-height: 1em;
    font-size: 3em; }
  .text-xl-4x {
    line-height: 1em;
    font-size: 4em; } }

.lh-1 {
  line-height: 1 !important; }

.fw-medium {
  font-weight: 500 !important; }

.cursor-pointer {
  cursor: pointer !important; }

@keyframes float-sm {
  0% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0.2));
    transform: translatey(0px); }
  50% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0));
    transform: translatey(-6px); }
  100% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0.2));
    transform: translatey(0px); } }

@keyframes float-lg {
  0% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0.2));
    transform: translatey(0px); }
  50% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0));
    transform: translatey(-20px); }
  100% {
    filter: drop-shadow(0 25px 15px rgba(0, 0, 0, 0.2));
    transform: translatey(0px); } }

.alert {
  margin-bottom: 1.5rem; }
  .alert.alert-dismissible > *:nth-last-child(2) {
    margin-bottom: 0 !important; }
  .alert.alert-success {
    background-color: #96ba20;
    border-color: #96ba20;
    color: #ffffff; }
  .alert strong {
    color: inherit; }
  .alert .icon-cross {
    position: absolute;
    top: 0;
    right: 0;
    padding: inherit;
    cursor: pointer; }
  .alert ul {
    margin-top: 0.25rem;
    padding-left: 1rem; }

.breadcrumb {
  font-size: 0.8rem; }
  @media (max-width: 767.98px) {
    .breadcrumb {
      white-space: nowrap;
      overflow: hidden;
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
      .breadcrumb .breadcrumb-item {
        display: inline; }
        .breadcrumb .breadcrumb-item::before {
          vertical-align: middle;
          margin-bottom: 3px; }
      .breadcrumb .breadcrumb-item + .breadcrumb-item {
        padding-left: .25rem; }
        .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
          padding-right: .25rem; } }
  .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    font-family: 'base';
    margin-top: 0.1em;
    content: '\e008'; }

.btn-mobile-menu-alt {
  padding: 1.2rem 0.7rem;
  transition: 300ms all ease-in;
  border: 1px solid #9d9c93;
  margin-left: 1rem;
  align-self: center;
  position: relative;
  cursor: pointer;
  display: flex;
  height: auto;
  width: auto;
  right: 0;
  left: 0;
  top: 0; }
  @media (min-width: 768px) {
    .btn-mobile-menu-alt {
      margin-left: 2rem; } }
  .btn-mobile-menu-alt:hover,
  .open-menu .btn-mobile-menu-alt {
    border: 1px solid #5d5c54;
    background: rgba(157, 156, 147, 0.1);
    transition: 300ms all ease-in; }
    .btn-mobile-menu-alt:hover .lines,
    .open-menu .btn-mobile-menu-alt .lines {
      background-color: #5d5c54;
      transform: scale(0.9); }
      .btn-mobile-menu-alt:hover .lines::after, .btn-mobile-menu-alt:hover .lines::before,
      .open-menu .btn-mobile-menu-alt .lines::after,
      .open-menu .btn-mobile-menu-alt .lines::before {
        background-color: #5d5c54; }
  .btn-mobile-menu-alt .lines {
    transition: 300ms all ease-in;
    background-color: #9d9c93;
    display: inline-block;
    width: 1.5rem;
    position: relative;
    height: 3px; }
    .btn-mobile-menu-alt .lines::after, .btn-mobile-menu-alt .lines::before {
      background-color: #9d9c93;
      position: absolute;
      height: inherit;
      width: inherit;
      content: ''; }
    .btn-mobile-menu-alt .lines::before {
      transition: 200ms all ease-in;
      top: -9px; }
      .open-menu .btn-mobile-menu-alt .lines::before {
        transform: rotate(45deg) scaleX(1.1);
        transition: 200ms all ease-in;
        transform-origin: top left;
        margin-left: 3px; }
    .btn-mobile-menu-alt .lines::after {
      transition: 200ms all ease-in;
      bottom: -9px; }
      .open-menu .btn-mobile-menu-alt .lines::after {
        transform: rotate(-45deg) scaleX(1.1);
        transition: 200ms all ease-in;
        transform-origin: bottom left;
        margin-left: 3px; }
    .open-menu .btn-mobile-menu-alt .lines {
      background-color: rgba(157, 156, 147, 0.1);
      transition: 200ms all ease-in; }

.intro-image img {
  max-width: 60vw; }
  @media (min-width: 768px) {
    .intro-image img {
      width: 400px; } }

.row-intro {
  position: relative; }
  .row-intro:not(.row-intro--academy) h1 {
    position: relative; }
    .row-intro:not(.row-intro--academy) h1 .intro-icon {
      transform: translateY(-50%);
      margin-left: -2rem;
      margin-top: 0.5rem;
      top: 50%;
      left: -20%;
      position: absolute;
      width: 20%; }
  .row-intro--academy h1 {
    position: relative; }
    .row-intro--academy h1 .intro-icon {
      margin-bottom: 24px;
      display: block; }
      @media (max-width: 991.98px) {
        .row-intro--academy h1 .intro-icon {
          width: 128px; } }
      @media (min-width: 768px) {
        .row-intro--academy h1 .intro-icon {
          transform: translateY(-50%);
          margin-left: -2rem;
          margin-top: 0.5rem;
          top: 50%;
          left: -20%;
          position: absolute;
          margin-bottom: 0;
          width: 25%; } }
      @media (min-width: 992px) {
        .row-intro--academy h1 .intro-icon {
          left: -30%;
          width: 35%; } }

.list-block {
  padding: 0;
  margin: 0; }
  .list-block .list-block-item {
    list-style: none;
    padding: 0;
    margin: 0; }

.list-group-flush .list-group-item {
  padding-right: 0;
  padding-left: 0; }

.list-group-item-toggle + div {
  margin-top: 0.25rem; }

.list-group-item-toggle strong {
  cursor: pointer; }
  .list-group-item-toggle strong::before {
    transition: 200ms all ease-in;
    transform: rotate(90deg);
    vertical-align: middle;
    display: inline-block;
    font-family: 'base';
    margin-top: -0.1em;
    content: '\e008'; }
  .list-group-item-toggle strong.collapsed::before {
    transform: rotate(0deg); }

.list-icon {
  margin: 0 0 1.5rem;
  padding: 0; }
  .list-icon li {
    padding: 0 0 0 2rem;
    list-style: none;
    margin: 0; }
    .list-icon li::before {
      margin: -0.2rem 0.3rem 0 -2.3rem;
      vertical-align: middle;
      display: inline-block;
      height: 1.7rem;
      width: 1.7rem;
      content: ''; }
  .list-icon.list-icon-check li::before {
    content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' class='jam jam-check' preserveAspectRatio='xMinYMin' viewBox='-5 -7 24 24'><defs/><path fill='rgb(175,158,151)' d='M5.486 9.73a.997.997 0 01-.707-.292L.537 5.195A1 1 0 111.95 3.78l3.535 3.535L11.85.952a1 1 0 011.415 1.414L6.193 9.438a.997.997 0 01-.707.292z'/></svg>"); }

.slider:not(.slick-initialized) .slide-item:not(:first-child) {
  display: none; }

.slider:not(.slick-initialized):not(.slick-dotted) .slick-dots {
  display: none; }

.slider-lazy:not(.slick-initialized) .col-4:nth-child(n+4) {
  display: none; }

.slider-lazy:not(.slick-initialized) .col-2:nth-child(n+7) {
  display: none; }

[class^="slider"] ul {
  list-style: none; }
  [class^="slider"] ul button {
    transition: 600ms opacity ease-in;
    border: 1px solid transparent;
    background: transparent;
    color: transparent;
    opacity: 0 !important;
    position: relative;
    z-index: -1; }

[class^="slider"] .slide-item,
[class^="slider"] .slick-slider {
  overflow: hidden; }

[class^="slider"] .slide-item {
  position: relative; }

[class^="slider"] .slick-list.draggable .slide-item {
  cursor: -webkit-grab;
  cursor: grab; }
  [class^="slider"] .slick-list.draggable .slide-item:active {
    cursor: -webkit-grabbing;
    cursor: grabbing; }
  [class^="slider"] .slick-list.draggable .slide-item:only-child {
    cursor: auto; }

[class^="slider"] .slick-list:not(.draggable) .slide-item {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text; }

[class^="slider"] .slick-prev,
[class^="slider"] .slick-next {
  height: 2rem;
  width: 2rem;
  z-index: 98; }
  [class^="slider"] .slick-prev::before,
  [class^="slider"] .slick-next::before {
    font-size: 2rem;
    font-family: 'base';
    color: #77766c;
    display: block; }

[class^="slider"] .slick-prev {
  left: 3rem; }
  [class^="slider"] .slick-prev::before {
    content: "\e007";
    color: #ffffff; }

[class^="slider"] .slick-next {
  right: 3rem; }
  [class^="slider"] .slick-next::before {
    content: "\e008";
    color: #ffffff; }

[class^="slider"].slick-outsidearrows .slick-prev {
  left: -3rem; }

[class^="slider"].slick-outsidearrows .slick-next {
  right: -3rem; }

[class^="slider"].slick-dotted {
  margin-bottom: 3rem !important; }

[class^="slider"].slick-inside-dots.slick-dotted {
  margin-bottom: 0 !important; }
  [class^="slider"].slick-inside-dots.slick-dotted .slick-dots {
    z-index: 99;
    bottom: 0; }

[class^="slider"].slick-dotted .slick-dots {
  transition: 300ms opacity ease-in;
  opacity: 1; }
  [class^="slider"].slick-dotted .slick-dots button {
    transition: 300ms opacity ease-in;
    opacity: 1 !important;
    z-index: 2; }

[class^="slider"] .slick-dots {
  transition: 300ms opacity ease-in;
  bottom: -3rem;
  opacity: 0; }
  [class^="slider"] .slick-dots button {
    transition: 300ms opacity ease-in;
    opacity: 0; }
  [class^="slider"] .slick-dots.inside-dots {
    margin: 0 0 1.5rem;
    position: absolute; }
  [class^="slider"] .slick-dots li {
    margin: 0 0.45rem;
    height: 0.9rem;
    width: 0.9rem; }
    [class^="slider"] .slick-dots li button {
      text-align: center;
      height: 0.9rem;
      width: 0.9rem;
      padding: 0; }
      [class^="slider"] .slick-dots li button::before {
        font-size: 0.9rem;
        height: 0.9rem;
        width: 0.9rem;
        opacity: 0.25; }
    [class^="slider"] .slick-dots li.slick-active button::before {
      opacity: 1; }

.title-with-logo {
  position: relative; }
  .title-with-logo::after {
    transition: 600ms all ease-in-out;
    background-image: url("../../frontend/images/icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    margin-left: 8px;
    height: 36px;
    width: 36px;
    content: '';
    opacity: 1;
    left: 100%;
    top: -20px; }
    @media (min-width: 768px) {
      .title-with-logo::after {
        margin-left: 16px;
        height: 72px;
        width: 72px; } }

.title-with-logo-star,
.title-with-logo-wrapper {
  max-width: calc(100vw - 120px);
  position: relative;
  margin: auto; }
  @media (min-width: 768px) {
    .title-with-logo-star,
    .title-with-logo-wrapper {
      max-width: calc(100vw - 160px); } }
  .title-with-logo-star .line,
  .title-with-logo-wrapper .line {
    display: inline-block; }
    @media (min-width: 992px) {
      .story-caption .title-with-logo-star .line, .story-caption
      .title-with-logo-wrapper .line {
        display: block;
        float: right;
        clear: both; } }
    .title-with-logo-star .line:first-child,
    .title-with-logo-wrapper .line:first-child {
      max-width: calc(100vw - 80px);
      position: relative; }
      .title-with-logo-star .line:first-child::after,
      .title-with-logo-wrapper .line:first-child::after {
        transition: 600ms all ease-in-out;
        background-image: url("../../frontend/images/icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        margin-left: 8px;
        height: 36px;
        width: 36px;
        content: '';
        opacity: 1;
        left: 100%;
        top: -20px; }
        @media (min-width: 768px) {
          .title-with-logo-star .line:first-child::after,
          .title-with-logo-wrapper .line:first-child::after {
            margin-left: 16px;
            height: 72px;
            width: 72px; } }

.title-with-logo-wrapper-small {
  max-width: calc(100vw - 120px);
  position: relative;
  margin: auto; }
  .title-with-logo-wrapper-small .line {
    display: inline-block; }
    @media (min-width: 992px) {
      .title-with-logo-wrapper-small .line {
        display: block;
        float: right;
        clear: both; } }
    .title-with-logo-wrapper-small .line:first-child {
      max-width: calc(100vw - 80px);
      position: relative; }
      .title-with-logo-wrapper-small .line:first-child::after {
        transition: 600ms all ease-in-out;
        background-image: url("../../frontend/images/icon.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        margin-left: 8px;
        height: 36px;
        width: 36px;
        content: '';
        opacity: 1;
        left: 100%;
        top: -20px; }

.title-with-logo-small {
  position: relative; }
  .title-with-logo-small::after {
    background-image: url("../../frontend/images/icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    right: -64px;
    height: 48px;
    width: 48px;
    content: '';
    top: -20px; }

.title-with-logo-top-left {
  position: relative; }
  @media (max-width: 767.98px) {
    .title-with-logo-top-left::after {
      background-image: url("../../frontend/images/icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      height: 80px;
      width: 80px;
      content: '';
      top: -88px;
      left: 0; } }
  @media (min-width: 768px) {
    .title-with-logo-top-left::after {
      background-image: url("../../frontend/images/icon.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      position: absolute;
      left: -96px;
      height: 128px;
      width: 128px;
      content: '';
      top: -120px; } }

.video {
  position: relative; }
  .video .icon {
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transform: translate(-50%, -50%);
    transition: 300ms all ease;
    vertical-align: middle;
    pointer-events: none;
    top: 50%;
    left: 50%;
    position: absolute;
    line-height: 1em;
    font-size: 48px;
    color: #ffffff;
    z-index: 2; }
    .video:hover .icon {
      transform: translate(-50%, -50%) scale(1.2); }
    @media (min-width: 768px) {
      .video.video-with-overlay .icon {
        margin-top: -1rem; } }
  .video video {
    display: block; }
    .video.active video {
      position: relative;
      z-index: 3; }
  .video .video-poster {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    cursor: pointer; }
  .video .video-caption {
    border: 1px solid #dae0e5;
    border-top: 0;
    background: #ffffff;
    padding: 1rem; }
    @media (min-width: 768px) {
      .video .video-caption {
        padding: 0.5rem 0.75rem;
        bottom: 1rem;
        left: 1rem;
        right: 1rem;
        background: rgba(255, 255, 255, 0.95);
        position: absolute;
        color: #77766c; } }

article figure[style*="text-align: center"] {
  display: block; }

@media (max-width: 1199.98px) {
  article figure img {
    height: auto !important; } }

article figure figcaption {
  font-style: italic; }
  article figure figcaption::before {
    content: '— '; }

article blockquote {
  background-color: #86c6ee;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  color: #ffffff; }
  article blockquote > *:not([class]) {
    color: #ffffff; }
  article blockquote footer {
    color: #ffffff; }
    article blockquote footer strong {
      color: inherit; }

article ol {
  counter-reset: number;
  margin: 0 0 1.5rem;
  list-style: none;
  padding: 0; }
  article ol li {
    counter-increment: number;
    margin: 0 0 0 1.5rem;
    padding: 0; }
    article ol li::before {
      content: counters(number, ".") ". ";
      display: inline-block;
      margin-left: -1.5rem;
      font-weight: bold;
      width: 1.5rem;
      float: left; }
  article ol.counter-upper-latin {
    counter-reset: alphabet; }
    article ol.counter-upper-latin li {
      counter-increment: alphabet; }
      article ol.counter-upper-latin li::before {
        content: counter(alphabet, upper-latin) ". "; }

article .col-offered ul,
article .col-requested ul {
  padding-left: 1.25rem; }

article .col-offered p + ul,
article .col-requested p + ul {
  margin-top: -1.5rem; }

.childs {
  flex-flow: row wrap;
  display: flex; }
  .childs .row, .childs .slider-lazy:not(.slick-initialized) {
    width: 100%;
    width: calc(100% + 2rem);
    break-after: always; }
  .childs.color- .btn.active {
    color: #ffffff; }
  .childs .btn {
    padding: 0.375rem; }
  @media all and (min-width: 399px) {
    .childs img {
      max-width: 35vw; } }
  .childs a.active img {
    filter: grayscale(100%);
    opacity: 0.7; }
  .childs a.active .link-inverted, .childs a.active .footer-section .footer-menu a, .footer-section .footer-menu .childs a.active a, .childs a.active .menu-section .language-select a, .menu-section .language-select .childs a.active a {
    text-decoration: underline; }
  .childs.color-primary .btn.active {
    border-color: #5598cc;
    background: #5598cc;
    color: #ffffff; }
    .childs.color-primary .btn.active:hover {
      border-color: #5598cc;
      background: #5598cc; }
    .childs.color-primary .btn.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 152, 204, 0.25); }
  .childs.color-secondary .btn.active {
    border-color: #96ba20;
    background: #96ba20;
    color: #ffffff; }
    .childs.color-secondary .btn.active:hover {
      border-color: #96ba20;
      background: #96ba20; }
    .childs.color-secondary .btn.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(150, 186, 32, 0.25); }
  .childs.color-tertiary .btn.active {
    border-color: #ec6707;
    background: #ec6707;
    color: #ffffff; }
    .childs.color-tertiary .btn.active:hover {
      border-color: #ec6707;
      background: #ec6707; }
    .childs.color-tertiary .btn.active:focus {
      box-shadow: 0 0 0 0.2rem rgba(236, 103, 7, 0.25); }

.contact-box {
  position: fixed;
  bottom: 25px;
  right: 50px;
  z-index: 9999; }
  @media all and (max-width: 640px) {
    .contact-box {
      bottom: 15px;
      right: 15px; } }
  .contact-box.mobile {
    top: 0;
    bottom: 0;
    right: 0; }
    .contact-box.mobile .panel {
      max-width: 100%;
      height: 100vh;
      overflow: auto;
      z-index: 9999; }
      .contact-box.mobile .panel::after {
        display: none; }
    .contact-box.mobile button {
      position: fixed;
      bottom: 15px;
      right: 15px;
      z-index: 9998; }
  .contact-box button {
    float: right;
    margin-right: 0 !important;
    padding-bottom: 0;
    font-size: 18px;
    font-weight: 400;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .contact-box button i {
      padding-left: 10px;
      font-size: 25px;
      vertical-align: middle; }
  .contact-box .panel {
    max-width: 360px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    background: #fff;
    display: none; }
    .contact-box .panel::after {
      background-image: url("../img/content/contact-box-footer.png");
      background-repeat: no-repeat;
      background-position: bottom right;
      position: absolute;
      bottom: -20px;
      right: 40px;
      height: 20px;
      width: 34px;
      content: ''; }
    .contact-box .panel .panel-header {
      position: relative;
      padding: 50px 30px 30px;
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: #fff;
      border: 0;
      background-color: #5598cc; }
      .contact-box .panel .panel-header strong {
        font-size: 20px; }
      .contact-box .panel .panel-header i {
        position: absolute;
        top: 10px;
        right: 5px;
        font-size: 25px;
        cursor: pointer; }
    .contact-box .panel .panel-body {
      padding: 0;
      border: 0; }
      .contact-box .panel .panel-body ul li {
        padding: 20px 30px;
        font-size: 18px;
        border-bottom: 1px solid #e4e4e4; }
        .contact-box .panel .panel-body ul li:last-child {
          padding-bottom: 30px;
          border-bottom: 0; }
        .contact-box .panel .panel-body ul li i {
          float: left;
          margin-right: 20px;
          padding: 12px 0 0;
          width: 60px;
          height: 60px;
          font-size: 30px;
          text-align: center;
          color: #5598cc;
          border: 3px solid #5598cc;
          border-radius: 50%; }
        .contact-box .panel .panel-body ul li .icon-whatsapp {
          padding-top: 8px;
          font-size: 35px; }
        .contact-box .panel .panel-body ul li strong {
          display: block;
          margin-top: 3px;
          font-size: 22px; }
        .contact-box .panel .panel-body ul li a {
          text-decoration: none; }

.cookies-card {
  background-color: #e9ecef;
  position: relative;
  z-index: 3; }
  .cookies-card .cookie-graphic {
    align-items: center;
    display: flex;
    width: 3rem; }
  .cookies-card .cookie-message {
    align-items: center;
    display: flex;
    flex: 1; }
  .cookies-card .cookie-buttons {
    white-space: nowrap;
    align-items: center;
    display: flex; }
    @media (min-width: 576px) and (max-width: 1199.98px) {
      .cookies-card .cookie-buttons {
        padding-left: 3rem; } }

.cookies-section {
  background-color: #e1e1de;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 101; }
  .cookies-section .container {
    align-items: center;
    max-width: 1480px;
    min-height: 124px;
    display: flex; }
  .cookies-section ~ .header-section {
    top: 174px; }
    @media (min-width: 768px) {
      .cookies-section ~ .header-section {
        top: 159px; } }
    @media (min-width: 992px) {
      .cookies-section ~ .header-section {
        top: 124px; } }
  .cookies-section .cookie-graphic {
    align-items: center;
    display: flex;
    width: 4rem; }
  .cookies-section .cookie-message {
    align-items: center;
    display: flex;
    flex: 1; }
  .cookies-section .cookie-buttons {
    white-space: nowrap;
    align-items: center;
    display: flex; }
    .cookies-section .cookie-buttons .btn {
      white-space: initial;
      font-size: 1rem; }
    @media (min-width: 576px) and (max-width: 1199.98px) {
      .cookies-section .cookie-buttons {
        padding-left: 4rem; } }

.cta-image {
  mix-blend-mode: darken; }
  @media (min-width: 992px) {
    article .cta-image {
      max-height: 240px; } }

.cta-sticky-button {
  position: fixed;
  z-index: 10;
  padding: 0;
  bottom: 0;
  top: auto;
  right: 0;
  left: 0; }
  .cta-sticky-button .btn {
    width: 100%; }
  @media (min-width: 992px) {
    .cta-sticky-button {
      position: fixed;
      bottom: unset;
      right: unset;
      padding: 0;
      top: 364px;
      left: 0; }
      .cta-sticky-button .btn {
        width: auto; } }

.dev-grid {
  bottom: 1rem;
  left: 2rem;
  position: fixed;
  z-index: 9002;
  background: #ffffff;
  border-radius: 0;
  padding: 0.33333rem 1rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
  opacity: 0.2; }
  .dev-grid:hover {
    opacity: 1; }
  .dev-grid .dev-grid-toggle {
    position: relative;
    z-index: 3; }
    .dev-grid .dev-grid-toggle:checked ~ .dev-grid-container {
      display: block; }
  .dev-grid .dev-grid-label {
    position: relative;
    user-select: none;
    z-index: 3; }
  .dev-grid .dev-grid-container {
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    position: fixed;
    z-index: 1;
    display: none; }
  .dev-grid .container,
  .dev-grid .container-xl {
    position: relative; }
    .dev-grid .container [class^="col"],
    .dev-grid .container-xl [class^="col"] {
      position: relative; }
      .dev-grid .container [class^="col"]::before,
      .dev-grid .container-xl [class^="col"]::before {
        background: rgba(0, 0, 0, 0.1);
        display: block;
        height: 100vh;
        content: '';
        width: 100%; }

#error-404 {
  background-color: #ffffff;
  min-height: 100vh; }

.error-header {
  border-bottom: 5px solid #e6e6e0;
  background-color: #f6f6f4;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 0.5rem;
  position: fixed;
  height: 50%;
  background-image: url("../../frontend/images/bg/lines-1.svg");
  background-position: center 150px;
  background-repeat: no-repeat;
  background-size: 100vw; }
  .error-header .error-message {
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto -21px;
    position: absolute;
    max-width: 450px;
    z-index: 3; }
    .error-header .error-message .error-logo {
      padding: 1.5rem 2rem;
      text-align: left;
      display: block; }
      .error-header .error-message .error-logo svg,
      .error-header .error-message .error-logo img {
        max-height: 2rem;
        margin-right: auto;
        max-width: 100%;
        width: auto; }
    .error-header .error-message .error-title {
      border: 1px solid #d4d4ca;
      border-bottom: 0;
      padding: 1rem 2rem 0;
      position: relative;
      background: #fff; }
      .error-header .error-message .error-title h1,
      .error-header .error-message .error-title .h1 {
        padding-right: 4rem;
        margin-bottom: 1rem;
        font-size: 1.5em;
        color: #9d9c93; }
      .error-header .error-message .error-title span {
        top: 1rem;
        right: -2rem;
        position: absolute;
        font-size: 1.5em;
        color: #9d9c93;
        margin: 0; }

.error-content {
  border: 1px solid #d4d4ca;
  border-top: 0;
  padding: 1.25rem 2rem 1.5rem;
  top: 50%;
  left: 0;
  right: 0;
  width: calc(100% - 16px);
  background: #fff;
  max-width: 450px;
  position: fixed;
  margin: 0 auto;
  z-index: 4; }

@media (max-width: 991.98px) {
  .error-header {
    position: relative; }
    .error-header .error-message {
      position: relative; }
      .error-header .error-message .error-logo {
        padding: 1rem; }
        .error-header .error-message .error-logo img,
        .error-header .error-message .error-logo svg {
          max-height: 3rem; }
      .error-header .error-message .error-title {
        padding: 1rem; }
  .error-content {
    padding: 0 1rem 1rem;
    position: relative;
    top: 0; } }

@media (max-width: 767.98px) {
  .error-header .error-message .error-title h1,
  .error-header .error-message .error-title .h1 {
    font-size: 18px !important; } }

.hs-input:not([type=file]) {
  background-color: unset !important; }

.hbspt-form .form-columns-1 {
  max-width: calc(100% + 32px);
  width: calc(100% + 32px);
  flex-direction: row;
  margin: 0 -16px;
  display: flex; }
  .hbspt-form .form-columns-1 > * {
    padding: 0 16px;
    flex: 1; }

.hbspt-form .form-columns-2 {
  max-width: calc(100% + 32px);
  width: calc(100% + 32px);
  flex-direction: column;
  margin: 0 -16px;
  display: flex; }
  @media (min-width: 768px) {
    .hbspt-form .form-columns-2 {
      flex-direction: row; } }
  @media (max-width: 991.98px) {
    .hbspt-form .form-columns-2 .hs-input {
      width: 100% !important; } }
  .hbspt-form .form-columns-2 > * {
    padding: 0 16px; }

.hbspt-form .hs-form-field {
  margin-bottom: 16px; }
  .hbspt-form .hs-form-field > .input {
    margin: 0 !important; }

.hbspt-form .hs-form-required {
  margin-left: 4px;
  color: #d95c5c; }

.hbspt-form .hs-form-booleancheckbox-display {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 1.5rem;
  user-select: none; }
  .hbspt-form .hs-form-booleancheckbox-display input {
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
    width: 1em !important;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #9d9c93;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact;
    border-radius: .25em;
    margin-left: -1.5em;
    float: left; }
    .hbspt-form .hs-form-booleancheckbox-display input:checked {
      background-color: #d3d800 !important;
      border-color: #d3d800 !important; }
    .hbspt-form .hs-form-booleancheckbox-display input + span {
      display: inline-block;
      margin: 0 !important; }

.hbspt-form textarea.hs-input,
.hbspt-form input.hs-input[type="text"],
.hbspt-form input.hs-input[type="tel"],
.hbspt-form input.hs-input[type="email"] {
  display: block;
  width: 100% !important;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .hbspt-form textarea.hs-input:focus,
  .hbspt-form input.hs-input[type="text"]:focus,
  .hbspt-form input.hs-input[type="tel"]:focus,
  .hbspt-form input.hs-input[type="email"]:focus {
    border-color: #b5b5ae !important;
    outline: unset; }

.hbspt-form textarea.hs-input {
  min-height: 8.6rem !important;
  width: 100% !important; }

.hbspt-form legend.hs-field-desc {
  font-size: 1em;
  color: #999999; }
  label + .hbspt-form legend.hs-field-desc {
    margin-bottom: 0.5em;
    margin-top: -0.25em; }

.hbspt-form ul.hs-error-msgs {
  margin: 4px 0 0 !important;
  font-style: italic;
  list-style: none;
  font-size: 13px;
  color: #d95c5c;
  padding: 0; }

.hbspt-form ul.inputs-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.hbspt-form .hs-button {
  display: inline-block;
  color: #fff;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  border-radius: 0;
  transition: 200ms all ease-in-out;
  padding: .425rem 1.5rem .375rem;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1.2;
  font-size: 1rem;
  background-color: #77766c;
  border-color: #77766c;
  border-style: solid;
  border-width: 1px; }
  .hbspt-form .hs-button.primary {
    background-color: #d3d800;
    border-color: #d3d800; }
    .hbspt-form .hs-button.primary:hover {
      box-shadow: 0 0.5rem 1rem #00000026;
      background-color: #aeb200;
      transform: scale(1.05);
      border-color: #a1a500; }
    .hbspt-form .hs-button.primary:focus {
      box-shadow: 0 0 0 0.2rem #b8bd0680;
      background-color: #aeb200;
      border-color: #a1a500;
      outline: none; }

.footer-section {
  background-color: #f6f6f4;
  color: #9d9c93; }
  @media (min-width: 768px) {
    .footer-section .footer-menu li:not(:first-child)::before {
      margin-left: -0.25rem;
      margin-right: 0.5rem;
      content: '|'; } }
  @media (max-width: 767.98px) {
    .footer-section .footer-menu li {
      margin: 0.5rem 0;
      display: block; } }

.map-section {
  max-width: 1920px;
  margin: auto; }
  .map-section .container-fluid, .map-section .container-sm, .map-section .container-md, .map-section .container-lg, .map-section .container-xl {
    max-width: 1480px;
    padding-right: 0;
    padding-left: 0; }
  .map-section .container-xl {
    border-bottom: 1px solid #f6f6f4;
    padding-right: 1rem;
    padding-left: 1rem; }

@keyframes marker-zoom {
  0% {
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  50% {
    opacity: 0;
    bottom: -10px;
    right: -10px;
    left: -10px;
    top: -10px; }
  100% {
    opacity: 0;
    bottom: -10px;
    right: -10px;
    left: -10px;
    top: -10px; } }

.location-map {
  position: relative; }
  @media (max-width: 767.98px) {
    .location-map {
      margin-left: -30%;
      width: 160%; } }
  .location-map .location-address-grouped {
    display: block; }
  .location-map .location-marker {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    background-color: #000;
    padding-top: 0.6%;
    position: absolute;
    cursor: pointer;
    padding-left: 0.6%; }
    .location-map .location-marker.active {
      box-shadow: 0 0 0 1px rgba(211, 216, 0, 0.5);
      background-color: #d3d800; }
  .location-map .location-tooltip {
    pointer-events: none;
    display: none;
    opacity: 0; }
  .location-map:not(.visited) .location-marker[data-location-id='67']::before {
    animation: marker-zoom 3000ms infinite;
    border: 2px solid #d3d800;
    pointer-events: none;
    position: absolute;
    content: '';
    bottom: 0;
    right: 0;
    left: 0;
    top: 0; }
  .location-map:not(.visited) .location-tooltip {
    position: absolute;
    margin-bottom: 8px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #000;
    border-radius: 6px;
    padding: 4px;
    width: 220px;
    word-break: break-word;
    font-size: 0.85rem;
    text-align: center;
    display: block;
    opacity: 1;
    z-index: 3; }
    .location-map:not(.visited) .location-tooltip::before {
      border-bottom: 6px solid transparent;
      border-left: 6px solid #000;
      position: absolute;
      content: '';
      top: 100%;
      left: 50%; }
    .location-map:not(.visited) .location-tooltip::after {
      border-bottom: 6px solid transparent;
      border-right: 6px solid #000;
      position: absolute;
      content: '';
      top: 100%;
      right: 50%; }
  .location-map .location-map-info {
    bottom: 0;
    right: 0;
    position: absolute;
    display: none;
    width: 640px; }
    @media (min-width: 1200px) {
      .location-map .location-map-info {
        display: block; } }
    .location-map .location-map-info > div {
      background-color: #d3d800;
      color: #ffffff; }
    .location-map .location-map-info .icon-chevron-small-right {
      margin-top: 1px; }
    .location-map .location-map-info .link-text {
      cursor: pointer; }
      .location-map .location-map-info .link-text:hover {
        text-decoration: underline; }
  .location-map .location-address:not(.d-none) ~ .location-map-notice {
    display: none !important; }

.location-list-toggle .icon {
  transition: 300ms all ease-in;
  margin-top: -0.3rem;
  line-height: 16px;
  margin-bottom: 0;
  height: 16px;
  width: 16px; }

.location-list-toggle:not(.collapsed) {
  background-color: #9d9c93;
  color: #ffffff; }
  .location-list-toggle:not(.collapsed) .icon {
    transform: rotate(180deg);
    transform-origin: center; }

@media (max-width: 1199.98px) {
  .location-list > .col-md-8:not(:first-child) .panel-body {
    position: relative; }
    .location-list > .col-md-8:not(:first-child) .panel-body::after {
      border: 0;
      border-top: 1px dashed #dae0e5;
      top: -1.5rem;
      left: 0;
      right: 0;
      position: absolute;
      content: '';
      height: 1px; } }

.slider-section {
  background-color: #ffffff;
  position: relative; }
  .slider-section [class^="slider"] .slick-next::before,
  .slider-section [class^="slider"] .slick-prev::before {
    text-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
    color: #9d9c93; }
  .slider-section .slick-dots {
    bottom: auto !important;
    z-index: 90 !important;
    right: 1.5rem;
    top: 1rem;
    width: auto;
    left: auto; }
  .slider-section .slick-dots li button::before,
  .slider-section .slick-dots li.slick-active button::before {
    text-shadow: 0 0 1rem rgba(255, 255, 255, 0.5);
    color: #9d9c93; }
  .slider-section .slide-title {
    font-weight: 700;
    line-height: 1.2em;
    font-size: 2rem;
    color: #9d9c93;
    display: block; }
    @media (min-width: 576px) {
      .slider-section .slide-title {
        line-height: 1.25em;
        font-size: 2.5rem; } }
    @media (min-width: 768px) {
      .slider-section .slide-title {
        line-height: 1.15em;
        font-size: 3.5rem; } }
    @media (min-width: 992px) {
      .slider-section .slide-title {
        line-height: 0.95em;
        font-size: 4.5rem; } }
  @media (max-width: 767.98px) {
    .slider-section .slide-item-row .slide-title-image {
      margin: 0 auto;
      width: 96px; }
    .slider-section .slide-item-row .slide-title {
      text-align: center; }
    .slider-section .slide-item-row .slide-image-subline {
      text-align: center; }
    .slider-section .slide-item-row .slide-image {
      margin: 0 auto; }
    .slider-section .slide-item-row .slide-caption > p:last-child {
      margin-bottom: 0; } }

.input-group-inside {
  position: relative; }
  .input-group-inside input {
    border: 0;
    outline: 0;
    background: none;
    box-shadow: none;
    position: relative;
    z-index: 2; }
  .input-group-inside .input-group-append,
  .input-group-inside .input-group-prepend {
    position: relative;
    background: none;
    box-shadow: none;
    z-index: 2;
    outline: 0;
    border: 0; }
    .input-group-inside .input-group-append .input-group-text,
    .input-group-inside .input-group-prepend .input-group-text {
      background: none;
      outline: 0;
      border: 0; }
      .input-group-inside .input-group-append .input-group-text .icon,
      .input-group-inside .input-group-prepend .input-group-text .icon {
        margin-bottom: -0.1em;
        margin-top: 0.2em; }
  .input-group-inside .input-group-prepend .input-group-text {
    padding-right: 0; }
  .input-group-inside .input-group-append .input-group-text {
    padding-left: 0; }
  .input-group-inside .input-group-inside-border {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
    background-color: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #ced4da;
    box-shadow: none;
    border-radius: 0; }
  .input-group-inside input:focus {
    border: 0;
    outline: 0;
    background: none;
    box-shadow: none; }
    .input-group-inside input:focus ~ .input-group-inside-border {
      color: #495057;
      background-color: #ffffff;
      border-color: #b5b5ae;
      outline: 0;
      box-shadow: none; }

.news-item {
  flex-direction: column;
  align-items: stretch;
  display: flex; }
  .news-item .card {
    transition: 300ms all ease-in;
    margin-bottom: 1.5rem;
    height: 100%;
    border: 0; }
    .news-item .card:hover {
      transition: 300ms all ease-in;
      transform: scale(1.025);
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
    @media (min-width: 576px) {
      .news-item .card {
        margin-bottom: 2rem; } }
  .news-item .news-caption {
    transition: 300ms background ease-in;
    background-color: #9d9c93;
    flex-direction: column;
    padding: 1.5rem;
    display: flex;
    color: #ffffff; }
    @media (min-width: 576px) {
      .news-item .news-caption {
        padding: 2rem; } }
    .news-item .news-caption:hover {
      background-color: #8c8b80 !important; }
    .news-item .news-caption.bg-primary:hover {
      background-color: #3a87c3 !important; }
    .news-item .news-caption.bg-primary-light:hover {
      background-color: #5598cc !important; }
    .news-item .news-caption.bg-secondary:hover {
      background-color: #7d9c1b !important; }
    .news-item .news-caption.bg-secondary-light:hover {
      background-color: #96ba20 !important; }
    .news-item .news-caption.bg-tertiary:hover {
      background-color: #c95806 !important; }
    .news-item .news-caption.bg-tertiary-light:hover {
      background-color: #ec6707 !important; }
    .news-item .news-caption .news-link:last-child {
      margin-top: auto; }
    .news-item .news-caption > * {
      color: inherit; }

.visible, .invisible {
  opacity: 0.0;
  transition: opacity 0.5s ease; }

.visible {
  opacity: 1.0; }

html.mobile.mobile-menu-opened,
html.mobile.mobile-menu-opened body {
  overflow-y: hidden; }

.header-section .mobile-menu {
  position: relative; }
  .header-section .mobile-menu > ul {
    transition: 800ms opacity ease-in;
    display: none;
    opacity: 0; }

.header-section.mobile .btn-mobile-menu {
  display: block;
  z-index: 999; }

.header-section.mobile .mobile-menu .mobile-menu-background {
  height: 20px;
  width: 20px;
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: all 300ms cubic-bezier(0, 0.995, 0.99, 1); }

.header-section.mobile .mobile-menu > ul > li {
  opacity: 1;
  margin-bottom: 20px;
  z-index: 99999;
  position: relative;
  padding-top: 20px; }
  .header-section.mobile .mobile-menu > ul > li:first-child {
    padding-top: inherit; }
  .header-section.mobile .mobile-menu > ul > li:after {
    content: '';
    height: 1px;
    border-top: 1px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 80%;
    margin: auto; }
  .header-section.mobile .mobile-menu > ul > li:first-child:after {
    display: none; }
  .header-section.mobile .mobile-menu > ul > li .header-menu {
    text-align: center;
    margin: 0 auto;
    float: none !important; }
    .header-section.mobile .mobile-menu > ul > li .header-menu ul {
      float: none !important; }
    .header-section.mobile .mobile-menu > ul > li .header-menu li {
      float: none !important; }
      .header-section.mobile .mobile-menu > ul > li .header-menu li a {
        font-size: 18px;
        text-transform: uppercase;
        text-decoration: none;
        letter-spacing: 1px; }
      .header-section.mobile .mobile-menu > ul > li .header-menu li a:hover,
      .header-section.mobile .mobile-menu > ul > li .header-menu li.active a {
        color: #5598cc !important; }
    .header-section.mobile .mobile-menu > ul > li .header-menu li.active a {
      font-weight: 400; }
    .header-section.mobile .mobile-menu > ul > li .header-menu .submenu:hover > a {
      background: none; }
  .header-section.mobile .mobile-menu > ul > li.menu-social {
    text-align: center; }
    .header-section.mobile .mobile-menu > ul > li.menu-social .socialmedia {
      position: relative; }
      .header-section.mobile .mobile-menu > ul > li.menu-social .socialmedia i {
        color: #5598cc; }

.header-section.expand {
  overflow: scroll;
  height: 100vh;
  z-index: 999;
  color: #9d9c93; }
  .header-section.expand .mobile-menu {
    width: 100%; }
    .header-section.expand .mobile-menu > ul {
      transition: 800ms opacity ease-in 800ms;
      display: block;
      margin: 40px auto 0;
      padding: 20px;
      list-style: none;
      opacity: 1;
      z-index: 2; }
    .header-section.expand .mobile-menu .nav {
      display: block;
      flex-wrap: nowrap; }
      .header-section.expand .mobile-menu .nav li {
        display: block !important;
        margin-right: 0 !important; }
        .header-section.expand .mobile-menu .nav li > a,
        .header-section.expand .mobile-menu .nav li .submenu > a {
          display: inline-block;
          margin-bottom: 4px;
          background: none; }
          .header-section.expand .mobile-menu .nav li > a::before,
          .header-section.expand .mobile-menu .nav li .submenu > a::before {
            display: none; }
        .header-section.expand .mobile-menu .nav li ul {
          margin-bottom: 4px !important; }
    .header-section.expand .mobile-menu .mobile-menu-background {
      opacity: 1;
      width: 300vw;
      height: 300vh;
      top: -150vh;
      left: -150vw;
      transition: all 800ms cubic-bezier(0, 0.995, 0.99, 1); }
    .header-section.expand .mobile-menu ul > li > .submenu ul.layer-2 {
      background: none !important;
      position: relative !important;
      top: auto !important;
      left: auto !important;
      z-index: 0 !important;
      padding: 0;
      margin: 0; }
      .header-section.expand .mobile-menu ul > li > .submenu ul.layer-2 a {
        font-size: 15px; }

.btn-mobile-menu {
  position: absolute;
  cursor: pointer;
  display: none;
  left: 1rem;
  height: 26px;
  width: 26px;
  top: 8px; }
  .btn-mobile-menu .icon {
    cursor: pointer; }
  .btn-mobile-menu .x, .btn-mobile-menu .y, .btn-mobile-menu .z {
    position: absolute;
    background: #fff;
    border-radius: 2px;
    transition: all 200ms ease-out;
    height: 3px;
    width: 26px; }
  .btn-mobile-menu .x {
    top: 0; }
  .btn-mobile-menu .y {
    top: 11px; }
  .btn-mobile-menu .z {
    top: 22px; }
  .btn-mobile-menu .collapse {
    top: 10px;
    background: #5598cc;
    transition: all 70ms ease-out; }
    .btn-mobile-menu .collapse:not(.show) {
      display: block; }
  .btn-mobile-menu .rotate30 {
    transform: rotate(30deg);
    transition: all 50ms ease-out; }
  .btn-mobile-menu .rotate150 {
    transform: rotate(150deg);
    transition: all 50ms ease-out; }
  .btn-mobile-menu .rotate45 {
    transform: rotate(45deg);
    transition: all 100ms ease-out; }
  .btn-mobile-menu .rotate135 {
    transform: rotate(135deg);
    transition: all 100ms ease-out; }

.mixed-collage {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  max-height: 85vh;
  height: 50vw;
  display: grid; }
  .mixed-collage > * {
    position: relative;
    overflow: hidden; }
    .mixed-collage > *:nth-child(1) {
      background-color: #eee;
      grid-column: 1/2;
      grid-row: 1/3; }
    .mixed-collage > *:nth-child(2) {
      background-color: #ddd;
      grid-column: 2/3;
      grid-row: 1/2; }
    .mixed-collage > *:nth-child(3) {
      background-color: #bbb;
      grid-column: 3/4;
      grid-row: 1/2; }
    .mixed-collage > *:nth-child(4) {
      background-color: #bbb;
      grid-column: 2/4;
      grid-row: 2/3; }
  .mixed-collage .video-img img {
    height: 140%;
    top: -20%; }
  .mixed-collage .video-img .icon-youtube {
    transition: 200ms all ease-in;
    transform: translate(-50%, -50%) scale(1);
    text-shadow: 0 0 24px rgba(0, 0, 0, 0.25);
    top: 50%;
    left: 50%;
    position: absolute;
    font-size: 60px;
    color: #fff;
    z-index: 2; }
    @media (max-width: 767.98px) {
      .mixed-collage .video-img .icon-youtube {
        font-size: 30px; } }
  .mixed-collage .video-img:hover .icon-youtube {
    transform: translate(-50%, -50%) scale(1.2);
    text-shadow: 0 0 48px rgba(0, 0, 0, 0.75);
    transition: 200ms all ease-in; }
  .mixed-collage img {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-position: center;
    position: absolute;
    object-fit: cover;
    height: 100%;
    width: 100%; }

#google_maps_wrapper {
  background: #f8f9fa;
  width: 100%;
  position: relative; }
  #google_maps_wrapper #google_maps {
    height: 300px; }
  #google_maps_wrapper #google_maps_info {
    background-color: #ffffff; }
    #google_maps_wrapper #google_maps_info a {
      text-decoration: none; }
    @media (max-width: 767.98px) {
      #google_maps_wrapper #google_maps_info {
        border: 1px solid #ddd;
        border-top: 0;
        position: relative;
        padding: 1rem;
        width: 100%; }
        #google_maps_wrapper #google_maps_info address {
          display: flex;
          flex-wrap: wrap;
          margin-right: -1rem;
          margin-left: -1rem;
          margin-bottom: 0; }
          #google_maps_wrapper #google_maps_info address span.h5 {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            width: 100%;
            padding-right: 1rem;
            padding-left: 1rem; }
          #google_maps_wrapper #google_maps_info address p {
            flex: 0 0 50%;
            max-width: 50%;
            position: relative;
            width: 100%;
            padding-right: 1rem;
            padding-left: 1rem;
            margin-bottom: 0; } }
    @media (min-width: 768px) {
      #google_maps_wrapper #google_maps_info {
        padding: 1rem 5rem 1rem 1rem;
        position: absolute;
        right: 1rem;
        top: 1rem; } }

.social-follow a {
  border: 1px solid #9d9c93;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: #9d9c93;
  padding: 0.2rem;
  height: 2.1rem;
  width: 2.1rem; }

.social-follow .facebook:hover a {
  border-color: #3b5998;
  background: #3b5998;
  color: #ffffff; }

.social-follow .instagram:hover a {
  border-color: #833ab4;
  background: #833ab4;
  color: #ffffff; }

.social-follow .linkedin:hover a {
  border-color: #0077b5;
  background: #0077b5;
  color: #ffffff; }

.social-follow .pinterest:hover a {
  border-color: #bd081c;
  background: #bd081c;
  color: #ffffff; }

.social-follow .twitter:hover a {
  border-color: #0f1419;
  background: #0f1419;
  color: #ffffff; }

.social-follow .vimeo:hover a {
  border-color: #1ab7ea;
  background: #1ab7ea;
  color: #ffffff; }

.social-follow .youtube:hover a {
  border-color: #cd201f;
  background: #cd201f;
  color: #ffffff; }

.social-share a {
  border: 1px solid #9d9c93;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: #9d9c93;
  padding: 0.2rem;
  height: 2.1rem;
  width: 2.1rem; }

.social-share .facebook:hover a {
  border-color: #3b5998;
  background: #3b5998;
  color: #ffffff; }

.social-share .instagram:hover a {
  border-color: #833ab4;
  background: #833ab4;
  color: #ffffff; }

.social-share .linkedin:hover a {
  border-color: #0077b5;
  background: #0077b5;
  color: #ffffff; }

.social-share .pinterest:hover a {
  border-color: #bd081c;
  background: #bd081c;
  color: #ffffff; }

.social-share .twitter:hover a {
  border-color: #0f1419;
  background: #0f1419;
  color: #ffffff; }

.social-share .vimeo:hover a {
  border-color: #1ab7ea;
  background: #1ab7ea;
  color: #ffffff; }

.social-share .youtube:hover a {
  border-color: #cd201f;
  background: #cd201f;
  color: #ffffff; }

.social-share .icon {
  margin-top: 0; }

@media all and (max-width: 560px) {
  .slider-news {
    margin-right: -1rem;
    margin-left: -1rem; } }

.slider-news .slick-list {
  overflow: visible; }

.slider-news .slick-track {
  align-items: stretch;
  flex-direction: row;
  display: flex; }
  .slider-news .slick-track .slide-item {
    overflow: visible;
    height: auto;
    flex: 1; }
    .slider-news .slick-track .slide-item .card {
      margin-bottom: 0;
      height: 100%; }

.slider-history .slick-prev,
.slider-history .slick-next {
  transition: 200ms box-shadow ease-in;
  background-color: #ffffff;
  border-radius: 50%;
  height: 2rem;
  width: 2rem; }
  @media (min-width: 576px) {
    .slider-history .slick-prev,
    .slider-history .slick-next {
      height: 3rem;
      width: 3rem; } }
  .slider-history .slick-prev:hover, .slider-history .slick-prev:focus,
  .slider-history .slick-next:hover,
  .slider-history .slick-next:focus {
    background-color: #f2f2f2;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); }
  .slider-history .slick-prev::before,
  .slider-history .slick-next::before {
    color: #9d9c93 !important; }

.slider-history .slick-prev {
  left: 3rem; }
  @media (min-width: 576px) {
    .slider-history .slick-prev {
      left: 4rem; } }

.slider-history .slick-next {
  right: 3rem; }
  @media (min-width: 576px) {
    .slider-history .slick-next {
      right: 4rem; } }

.slider-history .slick-prev,
.slider-history .slick-next {
  top: 100px; }
  @media (min-width: 576px) {
    .slider-history .slick-prev,
    .slider-history .slick-next {
      top: 140px; } }
  @media (min-width: 768px) {
    .slider-history .slick-prev,
    .slider-history .slick-next {
      top: 160px; } }
  @media (min-width: 992px) {
    .slider-history .slick-prev,
    .slider-history .slick-next {
      top: 180px; } }
  @media (min-width: 1200px) {
    .slider-history .slick-prev,
    .slider-history .slick-next {
      top: 200px; } }

.slider-history .slick-list {
  overflow: visible !important; }

.slider-history .slide-item img {
  transition: 400ms all ease-in;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  opacity: 0.8; }

.slider-history .slide-item.slick-current {
  transform: scale(1);
  overflow: visible;
  z-index: 9; }
  .slider-history .slide-item.slick-current img {
    transition: 400ms all ease-in;
    opacity: 1; }

.slider-history .slide-item:not(.slick-current) {
  transform: scale(0.8) translateX(20%) translateY(-4%);
  transition: 400ms all ease-in; }
  .slick-current + .slider-history .slide-item:not(.slick-current) {
    transform: scale(0.8) translateX(-20%) translateY(-4%);
    transition: 400ms all ease-in; }
  .slider-history .slide-item:not(.slick-current) p {
    transition: 400ms all ease-in;
    position: absolute;
    opacity: 0; }

.slider-history .slide-item.slick-current + .slide-item:not(.slick-current) {
  transform: scale(0.8) translateX(-20%) translateY(-4%);
  transition: 400ms all ease-in; }

.slider-media .slick-prev {
  left: 1.5rem; }
  @media (min-width: 768px) {
    .slider-media .slick-prev {
      left: 3rem; } }
  .slider-media .slick-prev::before {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    content: "\e007";
    color: #ffffff; }

.slider-media .slick-next {
  right: 1.5rem; }
  @media (min-width: 768px) {
    .slider-media .slick-next {
      right: 3rem; } }
  .slider-media .slick-next::before {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    content: "\e008";
    color: #ffffff; }

.slider-media .slick-disabled {
  opacity: 0; }

.slider-media .item {
  position: relative; }
  .slider-media .item a {
    overflow: hidden;
    display: block; }
    .slider-media .item a img {
      transition: 200ms transform ease-in;
      transform: scale(1); }
    .slider-media .item a .icon {
      transition: 200ms transform ease-in;
      transform: translate(-50%, -50%) scale(1); }
    .slider-media .item a:hover .icon {
      transform: translate(-50%, -50%) scale(1.1); }
  .slider-media .item .icon {
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    z-index: 2; }

.body-section .body-section-title {
  text-align: center; }
  .body-section .body-section-title .title-with-logo-wrapper {
    display: inline-block; }
  .body-section .body-section-title .title {
    position: relative;
    display: inline; }
  @media (max-width: 991.98px) {
    .body-section .body-section-title.col-md-10 {
      padding-right: 112px; } }

.c2a-section {
  position: relative;
  z-index: 2; }
  .c2a-section .c2a-section-title {
    max-width: 350px; }
  .c2a-section__content {
    flex-direction: row;
    display: flex; }
  .c2a-section a {
    flex-basis: auto; }
    .c2a-section a svg {
      transition: 150ms all ease-in;
      margin: 0 auto 1rem;
      transform: scale(1);
      max-width: 4rem;
      display: block;
      height: 3rem; }
      @media (min-width: 768px) {
        .c2a-section a svg {
          height: 4rem;
          max-width: 5rem; } }
    .c2a-section a:hover svg {
      transition: 150ms all ease-in;
      transform: scale(1.1); }

.close-menu {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 400ms all ease-in;
  background-color: #343a40;
  position: fixed;
  height: 100vh;
  z-index: -1;
  opacity: 0; }
  .open-menu .close-menu,
  .open-login .close-menu {
    transition: 400ms all ease-out;
    opacity: 0.1;
    z-index: 98; }

.footerimage-section {
  max-width: 1920px;
  margin: auto; }
  .footerimage-section .container-fluid, .footerimage-section .container-sm, .footerimage-section .container-md, .footerimage-section .container-lg, .footerimage-section .container-xl {
    padding: 0; }
  .footerimage-section figure {
    width: 100%;
    margin: 0; }

.gallery-section {
  max-width: 1920px;
  margin: auto; }
  .gallery-section .item-video {
    position: relative; }
    .gallery-section .item-video .icon-youtube {
      transition: 200ms all ease-in-out;
      text-shadow: 0 0 0.2em rgba(52, 58, 64, 0.15);
      transform: translate(-50%, -50%);
      position: absolute;
      line-height: 0.8em;
      font-size: 64px;
      color: #ffffff;
      left: 50%;
      top: 50%; }
      @media all and (max-width: 640px) {
        .gallery-section .item-video .icon-youtube {
          font-size: 40px; } }
    .gallery-section .item-video a:hover .icon-youtube {
      text-shadow: 0 0 0.2em rgba(52, 58, 64, 0.25);
      font-size: 80px; }
      @media all and (max-width: 640px) {
        .gallery-section .item-video a:hover .icon-youtube {
          font-size: 52px; } }

.header-section {
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  transition: 400ms all ease-out;
  top: 0;
  left: 0;
  right: 0;
  background: #f6f6f4;
  position: fixed;
  color: #9d9c93;
  z-index: 100;
  height: 80px; }
  .header-section.fixed {
    box-shadow: 0 0 32px rgba(157, 156, 147, 0.4);
    transition: 400ms all ease-in;
    position: fixed;
    z-index: 100; }
    .open-menu .header-section.fixed,
    .open-login .header-section.fixed {
      box-shadow: none; }
  .header-section .container-fluid, .header-section .container-sm, .header-section .container-md, .header-section .container-lg, .header-section .container-xl {
    justify-items: center;
    align-items: center;
    position: relative;
    max-width: 1480px;
    display: flex; }
  .header-section .logo-container .logo {
    max-width: 65vw;
    display: block;
    width: 250px; }
    .header-section .logo-container .logo svg {
      height: 37px; }
      .header-section .logo-container .logo svg path {
        transition: 300ms all ease-in; }
      .header-section .logo-container .logo svg:hover path {
        fill: #5d5c54; }
  .header-section .header-login {
    transition: 300ms all ease-in;
    text-decoration: none;
    cursor: pointer; }
    .header-section .header-login .link {
      display: inline-block;
      position: relative;
      margin-top: -2px; }
      .header-section .header-login .link::after {
        background: rgba(93, 92, 84, 0.5);
        transition: 300ms all ease-in;
        position: absolute;
        top: 100%;
        left: 0;
        margin-top: 1px;
        content: '';
        height: 2px;
        width: 0; }
    .header-section .header-login svg {
      transition: 300ms all ease-in;
      height: 48px;
      width: 50px; }
      .header-section .header-login svg path {
        transition: 300ms all ease-in; }
    .header-section .header-login:hover,
    .open-login .header-section .header-login {
      color: #5d5c54; }
      .header-section .header-login:hover .link::after,
      .open-login .header-section .header-login .link::after {
        width: 100%; }
      .header-section .header-login:hover svg,
      .open-login .header-section .header-login svg {
        transform: scale(0.95); }
        .header-section .header-login:hover svg path,
        .open-login .header-section .header-login svg path {
          fill: rgba(93, 92, 84, 0.5); }

[data-menu-measure="child"] {
  white-space: nowrap; }

.highlight-section .container-fluid, .highlight-section .container-sm, .highlight-section .container-md, .highlight-section .container-lg, .highlight-section .container-xl {
  max-width: 1480px; }
  @media (max-width: 767.98px) {
    .highlight-section .container-fluid, .highlight-section .container-sm, .highlight-section .container-md, .highlight-section .container-lg, .highlight-section .container-xl {
      padding-right: 0;
      padding-left: 0; }
      .highlight-section .container-fluid figure, .highlight-section .container-sm figure, .highlight-section .container-md figure, .highlight-section .container-lg figure, .highlight-section .container-xl figure {
        margin: 0; } }

.info-popup {
  padding-bottom: 2rem;
  padding-top: 2rem;
  position: relative; }
  @media (min-width: 768px) {
    .info-popup {
      padding-bottom: 5rem;
      padding-top: 5rem; } }
  .info-popup [data-toggle-target] {
    cursor: pointer; }
  .info-popup .text-default[data-toggle-target] {
    transition: 150ms all ease-in; }
    .info-popup .text-default[data-toggle-target]:hover {
      color: #858378 !important; }
  .info-popup .info-popup-container {
    max-width: 90%;
    max-width: calc(100vw - 2rem);
    transform: translate(-50%, -50%) scale(0);
    max-height: calc(100vh - 2rem);
    border-radius: 0.3rem;
    transition: 500ms all ease-in-out;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    transform-origin: center;
    top: 50%;
    left: 50%;
    position: absolute;
    background: #ffffff;
    padding: 2rem;
    text-align: left;
    overflow: auto;
    width: 800px;
    z-index: -1;
    opacity: 0; }
    .info-popup .info-popup-container.active {
      transform: scale(1) translate(-50%, -50%);
      transition: 500ms all ease-in-out;
      z-index: 999;
      opacity: 1; }
    .info-popup .info-popup-container .info-popup-container-close {
      transition: 150ms all ease-in;
      height: 1.2rem;
      position: absolute;
      width: 1.2rem;
      right: 2rem;
      cursor: pointer;
      top: 2rem; }
      @media (max-width: 767.98px) {
        .info-popup .info-popup-container .info-popup-container-close {
          right: 1rem;
          top: 1rem; } }
      .info-popup .info-popup-container .info-popup-container-close::before {
        background-color: #9d9c93;
        transform-origin: top left;
        transform: rotate(45deg);
        position: absolute;
        top: 0;
        left: 2px;
        content: '';
        height: 3px;
        width: 120%; }
      .info-popup .info-popup-container .info-popup-container-close::after {
        background-color: #9d9c93;
        transform-origin: top left;
        transform: rotate(-45deg);
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        height: 3px;
        width: 120%; }
      .info-popup .info-popup-container .info-popup-container-close:hover {
        transform: rotate(90deg); }
        .info-popup .info-popup-container .info-popup-container-close:hover::after, .info-popup .info-popup-container .info-popup-container-close:hover::before {
          background-color: #6a6960; }

.login-section {
  border-bottom: 10px solid #f6f6f4;
  max-height: calc(100vh - 80px);
  transition: 400ms all ease-in;
  transform: translateY(-150%);
  background-color: #ffffff;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-top: 80px;
  position: fixed;
  overflow: auto;
  z-index: 99;
  right: 0;
  left: 0;
  top: 0; }
  @media (min-width: 768px) {
    .login-section {
      padding-bottom: 80px;
      padding-top: 80px; } }
  .open-login .login-section {
    box-shadow: 0 16px 32px rgba(52, 58, 64, 0.05);
    transition: 400ms all ease-in;
    transform: translateY(0); }

.menu-section {
  border-bottom: 10px solid #f6f6f4;
  top: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh - 80px);
  transition: 400ms all ease-in;
  transform: translateY(-150%);
  background-color: #ffffff;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-top: 80px;
  position: fixed;
  overflow: auto;
  z-index: 99; }
  @media (min-width: 768px) {
    .menu-section {
      padding-bottom: 80px;
      padding-top: 80px; } }
  .open-menu .menu-section {
    box-shadow: 0 16px 32px rgba(52, 58, 64, 0.05);
    transition: 400ms all ease-in;
    transform: translateY(0); }
  .menu-section .mobile-login-menu .icon {
    transition: 100ms all ease-in;
    line-height: 0.5rem; }
  .menu-section .mobile-login-menu .list-block {
    font-size: 14px; }
  .menu-section .mobile-login-menu .active .h6 .icon {
    transition: 100ms all ease-in;
    transform: rotate(90deg);
    transform-origin: center; }
  .menu-section .mobile-login-menu .active .list-block {
    display: block !important; }
  .menu-section .menu .active > a,
  .menu-section .menu .active > .submenu > a {
    font-weight: 500; }
  .menu-section .menu ul {
    list-style: none;
    font-size: 16px;
    padding: 0;
    margin: 0; }
    @media (min-width: 768px) {
      .menu-section .menu ul {
        font-size: 19px; } }
    .menu-section .menu ul li {
      margin: 0.75rem 0;
      padding: 0; }
      .menu-section .menu ul li a {
        text-decoration: none; }
        .menu-section .menu ul li a span {
          position: relative; }
          .menu-section .menu ul li a span::after {
            transition: 300ms all ease-in;
            bottom: -2px;
            left: 0;
            position: absolute;
            background: #9d9c93;
            content: '';
            height: 1px;
            width: 0; }
          .menu-section .menu ul li a span:hover::after {
            width: 100%; }
      .menu-section .menu ul li.active > a span::after,
      .menu-section .menu ul li.active > .submenu > a span::after {
        height: 2px;
        width: 100%; }
    .menu-section .menu ul ul {
      margin-left: 1.5rem; }
  .menu-section .socialmedia {
    margin: 0; }
    .menu-section .socialmedia li {
      margin-bottom: 1rem; }
    .menu-section .socialmedia a {
      border: 1px solid #9d9c93;
      display: inline-block;
      text-align: center;
      border-radius: 50%;
      color: #9d9c93;
      padding: 0.2rem;
      height: 2.15rem;
      width: 2.15rem; }
    .menu-section .socialmedia .icon {
      margin-top: 0; }
    .menu-section .socialmedia .facebook:hover a {
      border-color: #3b5998;
      background: #3b5998;
      color: #ffffff; }
    .menu-section .socialmedia .instagram:hover a {
      border-color: #833ab4;
      background: #833ab4;
      color: #ffffff; }
    .menu-section .socialmedia .linkedin:hover a {
      border-color: #0077b5;
      background: #0077b5;
      color: #ffffff; }
    .menu-section .socialmedia .twitter:hover a {
      border-color: #0f1419;
      background: #0f1419;
      color: #ffffff; }
    .menu-section .socialmedia .vimeo:hover a {
      border-color: #1ab7ea;
      background: #1ab7ea;
      color: #ffffff; }
    .menu-section .socialmedia .youtube:hover a {
      border-color: #cd201f;
      background: #cd201f;
      color: #ffffff; }
  .menu-section .form-control {
    color: #9d9c93; }
    .menu-section .form-control::placeholder {
      color: #9d9c93; }
  .menu-section .input-group-text {
    color: #9d9c93; }
  .menu-section .language-select a {
    font-size: 17px; }
  .menu-section .language-select .active {
    font-weight: 500; }

.popup-section {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 300ms all ease-in;
  justify-content: center;
  flex-direction: column;
  pointer-events: none;
  align-items: center;
  overflow: hidden;
  position: fixed;
  padding: 1.5rem;
  display: flex;
  width: 100%;
  z-index: -1;
  opacity: 0; }
  .popup-section.active {
    transition: 300ms all ease-in;
    pointer-events: auto;
    z-index: 101;
    opacity: 1; }
  .popup-section::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    position: fixed;
    height: 100vh;
    content: '';
    width: 100%; }
  .popup-section.active .popup.active {
    transition: 600ms opacity ease-in 300ms;
    display: block;
    opacity: 1; }
  .popup-section .popup {
    transition: 600ms all ease-in;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.25);
    max-height: calc(100vh - (3rem));
    position: relative;
    max-width: 100%;
    display: none;
    margin: auto;
    width: 640px;
    opacity: 0;
    padding: 0;
    z-index: 2; }
    @media all and (min-width: 1600px) {
      .popup-section .popup {
        width: 800px; } }
    .popup-section .popup .svg-lines {
      position: absolute;
      overflow: hidden;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%; }
    .popup-section .popup::before {
      background-color: #ffffff;
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      left: 0;
      top: 0; }
    .popup-section .popup .popup-content {
      position: relative;
      overflow: visible;
      height: 100%;
      z-index: 2; }
      @media all and (min-width: 1600px) {
        .popup-section .popup .popup-content .p-xxl-5 {
          padding: 3rem !important; } }
      @media all and (min-width: 768px) and (max-width: 1600px) {
        .popup-section .popup .popup-content .h1 {
          white-space: nowrap;
          font-size: 2rem; } }
      .popup-section .popup .popup-content .h5 {
        margin: 12px 0 0; }
    @media (max-width: 1199.98px) {
      .popup-section .popup .popup-content-image {
        animation: float-sm 6s ease-in-out infinite; } }
    @media (min-width: 992px) {
      .popup-section .popup .popup-content-image {
        animation: float-lg 6s ease-in-out infinite; } }
    @media all and (min-width: 768px) and (max-width: 1600px) {
      .popup-section .popup .popup-content-image {
        padding-top: 2rem !important; } }
    .popup-section .popup .popup-close {
      transition: 300ms all ease;
      position: absolute;
      cursor: pointer;
      font-size: 2em;
      color: #ffffff;
      right: 1.5rem;
      top: 0.75rem;
      width: 1em;
      z-index: 3; }
      @media all and (min-width: 1600px) {
        .popup-section .popup .popup-close {
          top: 1.25rem;
          right: 2rem; } }
      @media (min-width: 992px) {
        .popup-section .popup .popup-close {
          color: #ffffff; } }
      .popup-section .popup .popup-close:hover {
        transform: scale(1.2); }
    .popup-section .popup .btn {
      user-select: none; }

.story-section.story-variant-1 {
  background-position-y: center;
  padding-bottom: 40px; }
  @media all and (max-width: 1400px) {
    .story-section.story-variant-1 {
      background-size: 1400px; } }
  @media (min-width: 576px) {
    .story-section.story-variant-1 {
      padding-bottom: 320px;
      margin-bottom: -260px;
      padding-top: 260px;
      margin-top: -260px; } }
  @media (min-width: 768px) {
    .story-section.story-variant-1 {
      padding-bottom: 540px;
      margin-bottom: -460px;
      padding-top: 560px;
      margin-top: -460px; } }
  .story-section.story-variant-1 .story-caption .h1 {
    display: inline-block; }
  @media (min-width: 768px) {
    .story-section.story-variant-1 .story-caption.text-md-right {
      padding-right: 82px; }
    .story-section.story-variant-1 .story-caption .story-caption__body,
    .story-section.story-variant-1 .story-caption .story-caption__button,
    .story-section.story-variant-1 .story-caption .story-caption__subtitle {
      margin-right: 0.75rem; } }

.story-section .position-relative {
  min-height: 340px; }
  @media all and (min-width: 1800px) {
    .story-section .position-relative .story-caption {
      position: absolute;
      right: 520px;
      top: 0; } }
  .story-section .position-relative .story-image {
    position: static; }
    @media all and (max-width: 767px) {
      .story-section .position-relative .story-image {
        padding-right: 0;
        padding-left: 0; }
        .story-section .position-relative .story-image img {
          display: block;
          margin: 0 -1rem -2.5rem -1rem;
          width: calc(100% + 2rem); } }
    @media all and (min-width: 768px) {
      .story-section .position-relative .story-image img {
        position: absolute;
        bottom: 0;
        right: 0;
        top: 0;
        height: calc(100% + 180px);
        width: auto;
        margin-top: -100px;
        margin-bottom: -80px;
        max-width: 50vw;
        object-fit: cover;
        object-position: left top; } }

@media all and (min-width: 1640px) {
  .col-xxl-10 {
    max-width: 83.33333%;
    flex: 0 0 83.33333%; } }
