.location-list-toggle {

    .icon {
        transition: 300ms all ease-in;
        margin-top: -0.3rem;
        line-height: 16px;
        margin-bottom: 0;
        height: 16px;
        width: 16px;
    }

    &:not(.collapsed) {
        background-color: $default;
        color: $white;

        .icon {
            transform: rotate(180deg);
            transform-origin: center;
        }
    }
}

.location-list {
    @include media-breakpoint-down(lg) {
        > .col-md-8 {
            &:not(:first-child) {
                .panel-body {
                    position: relative;

                    &::after {
                        border: 0; border-top: 1px dashed darken($light,10%);
                        top: (-$spacer*1.5); left: 0; right: 0;
                        position: absolute;
                        content: '';
                        height: 1px;
                    }
                }
            }
        }
    }
}
