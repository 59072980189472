.mixed-collage {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    max-height: 85vh;
    height: 50vw;
    display: grid;

    & > * {
        position: relative;
        overflow: hidden;

        &:nth-child(1) {
            background-color: #eee;
            grid-column: 1/2;
            grid-row: 1/3;
        }

        &:nth-child(2) {
            background-color: #ddd;
            grid-column: 2/3;
            grid-row: 1/2;
        }

        &:nth-child(3) {
            background-color: #bbb;
            grid-column: 3/4;
            grid-row: 1/2;
        }

        &:nth-child(4) {
            background-color: #bbb;
            grid-column: 2/4;
            grid-row: 2/3;
        }
    }

    .video-img {

        img {
            height: 140%;
            top: -20%;
        }

        .icon-youtube {
            transition: 200ms all ease-in;
            transform: translate(-50%,-50%) scale(1);
            text-shadow: 0 0 24px rgba(0,0,0,0.25);
            top: 50%; left: 50%;
            position: absolute;
            font-size: 60px;
            color: #fff;
            z-index: 2;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
            }
        }

        &:hover {
            .icon-youtube {
                transform: translate(-50%,-50%) scale(1.2);
                text-shadow: 0 0 48px rgba(0,0,0,0.75);
                transition: 200ms all ease-in;
            }
        }
    }

    img {
        top: 0; left: 0; bottom: 0; right: 0;
        object-position: center;
        position: absolute;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
}
