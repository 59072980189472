.footerimage-section {
    max-width: 1920px;
    margin: auto;

    .container-fluid {
        //max-width: 1480px;
        padding: 0;
    }

    figure {
        width: 100%;
        margin: 0;
    }
}
