.childs {
    flex-flow: row wrap;
    display: flex;

    .row {
        width: 100%; width: calc(100% + 2rem);
        break-after: always;
    }

    &.color- {
        .btn.active {
            color: $white;
        }
    }

    .btn {
        padding: 0.375rem;
    }

    @media all and (min-width: 399px) {
        img {
            max-width: 35vw;
        }
    }

    a.active {
        img {
            filter: grayscale(100%);
            opacity: 0.7;
        }
        .link-inverted {
            text-decoration: underline;
        }
    }

    &.color-primary {
        .btn.active {
            border-color: $primary;
            background: $primary;
            color: $white;

            &:hover {
                border-color: $primary;
                background: $primary;
                //color: $primary;
            }

            &:focus {
                box-shadow: 0 0 0 .2rem rgba($primary,.25);
            }
        }
    }

    &.color-secondary {
        .btn.active {
            border-color: $secondary;
            background: $secondary;
            color: $white;

            &:hover {
                border-color: $secondary;
                background: $secondary;
                //color: $secondary;
            }

            &:focus {
                box-shadow: 0 0 0 .2rem rgba($secondary,.25);
            }
        }
    }

    &.color-tertiary {
        .btn.active {
            border-color: $tertiary;
            background: $tertiary;
            color: $white;

            &:hover {
                border-color: $tertiary;
                background: $tertiary;
                //color: $tertiary
            }

            &:focus {
                box-shadow: 0 0 0 .2rem rgba($tertiary,.25);
            }
        }
    }
}
