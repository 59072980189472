@keyframes float-sm {
    0% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0.2));
        transform: translatey(0px);
    }
    50% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0));
        transform: translatey(-6px);
    }
    100% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0.2));
        transform: translatey(0px);
    }
}

@keyframes float-lg {
    0% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0.2));
        transform: translatey(0px);
    }
    50% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0));
        transform: translatey(-20px);
    }
    100% {
        filter: drop-shadow(0 25px 15px rgba(0,0,0,0.2));
        transform: translatey(0px);
    }
}
