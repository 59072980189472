.map-section {
    max-width: 1920px;
    margin: auto;

    .container-fluid {
        max-width: 1480px;
        padding-right: 0;
        padding-left: 0;
    }

    .container-xl {
        border-bottom: 1px solid $off-white;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@keyframes marker-zoom {
    0% {
        opacity: 1;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
    }
    50% {
        opacity: 0;
        bottom: -10px;
        right: -10px;
        left: -10px;
        top: -10px;
    }
    100% {
        opacity: 0;
        bottom: -10px;
        right: -10px;
        left: -10px;
        top: -10px;
    }
}

.location-map {
    @extend .clearfix;
    position: relative;

    @include media-breakpoint-down(sm) {
        margin-left: -30%;
        width: 160%;
    }

    .location-map-background {

    }

    .location-address-grouped {
        display: block;
    }

    .location-marker {
        box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
        background-color: $black;
        padding-top: 0.6%;
        position: absolute;
        cursor: pointer;
        padding-left: 0.6%;

        &.active {
            box-shadow: 0 0 0 1px rgba($secondary-light,0.5);
            background-color: $secondary-light;
        }
    }

    .location-tooltip {
        pointer-events: none;
        display: none;
        opacity: 0;
    }

    &:not(.visited) {
        .location-marker[data-location-id='67'] {
            &::before {
                animation: marker-zoom 3000ms infinite;
                border: 2px solid $secondary-light;
                pointer-events: none;
                position: absolute;
                content: '';
                bottom: 0;
                right: 0;
                left: 0;
                top: 0;
            }
        }
        .location-tooltip {
            position: absolute;
            margin-bottom: 8px;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
            background: #000;
            border-radius: 6px;
            padding: 4px;
            width: 220px;
            word-break: break-word;
            font-size: 0.85rem;
            text-align: center;
            display: block;
            opacity: 1;
            z-index: 3;

            &::before {
                border-bottom: 6px solid transparent;
                border-left: 6px solid #000;
                position: absolute;
                content: '';
                top: 100%;
                left: 50%;
            }

            &::after {
                border-bottom: 6px solid transparent;
                border-right: 6px solid #000;
                position: absolute;
                content: '';
                top: 100%;
                right: 50%;
            }
        }
    }

    .location-map-info {
        bottom: 0; right: 0;
        position: absolute;
        display: none;
        width: 640px;

        @include media-breakpoint-up(xl) {
            display: block;
        }

        > div {
            background-color: $secondary-light;
            color: $white;
        }

        .icon-chevron-small-right {
            margin-top: 1px;
        }

        .link-text {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .location-address:not(.d-none) ~ .location-map-notice {
        display: none !important;
    }
}
