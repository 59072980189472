.hs-input:not([type=file]) {
    background-color: unset !important;
}

.hbspt-form {

    .form-columns-1 {
        max-width: calc(100% + 32px);
        width: calc(100% + 32px);
        flex-direction: row;
        margin: 0 -16px;
        display: flex;

        > * {
            padding: 0 16px;
            flex: 1;
        }
    }

    .form-columns-2 {
        max-width: calc(100% + 32px);
        width: calc(100% + 32px);
        flex-direction: column;
        margin: 0 -16px;
        display: flex;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }

        @include media-breakpoint-down(md) {
            .hs-input {
                width: 100% !important;
            }
        }

        > * {
            padding: 0 16px;
        }
    }

    .hs-form-field {
        margin-bottom: 16px;

        > .input {
            margin: 0 !important;
        }
    }

    .hs-form-required {
        margin-left: 4px;
        color: $danger;
    }

    .hs-form-booleancheckbox-display {
        display: block;
        min-height: 1.5rem;
        padding-left: 1.5em;
        margin-bottom: 1.5rem;
        user-select: none;

        input {
            --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");

            width: 1em !important;
            height: 1em;
            margin-top: .25em;
            vertical-align: top;
            background-color: var(--bs-form-check-bg);
            background-image: var(--bs-form-check-bg-image);
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            border: 1px solid $default;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            -webkit-print-color-adjust: exact;
            color-adjust: exact;
            print-color-adjust: exact;

            border-radius: .25em;
            margin-left: -1.5em;
            float: left;

            &:checked {
                background-color: $secondary-light !important;
                border-color: $secondary-light !important;
            }

            + span {
                display: inline-block;
                margin: 0 !important;
            }
        }
    }

    textarea.hs-input,
    input.hs-input[type="text"],
    input.hs-input[type="tel"],
    input.hs-input[type="email"] {
        display: block;
        width: 100% !important;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
        background-color: #fff !important;
        background-clip: padding-box;
        border: 1px solid #ced4da !important;
        border-radius: 0;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

        &:focus {
            border-color: #b5b5ae !important;
            outline: unset;
        }
    }

    textarea.hs-input {
        min-height: 8.6rem !important;
        width: 100% !important;
    }

    legend.hs-field-desc {
        font-size: 1em;
        color: #999999;

        label + & {
            margin-bottom: 0.5em;
            margin-top: -0.25em;
        }
    }

    ul.hs-error-msgs {
        margin: 4px 0 0 !important;
        font-style: italic;
        list-style: none;
        font-size: 13px;
        color: $danger;
        padding: 0;
    }

    ul.inputs-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .hs-button {
        display: inline-block;
        color: #fff;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        user-select: none;
        border-radius: 0;

        transition: 200ms all ease-in-out;
        padding: .425rem 1.5rem .375rem;
        text-transform: uppercase;
        font-weight: 300;
        line-height: 1.2;
        font-size: 1rem;
        background-color: #77766c;
        border-color: #77766c;
        border-style: solid;
        border-width: 1px;

        &.primary {
            background-color: #d3d800;
            border-color: #d3d800;

            &:hover {
                box-shadow: 0 0.5rem 1rem #00000026;
                background-color: #aeb200;
                transform: scale(1.05);
                border-color: #a1a500;
            }

            &:focus {
                box-shadow: 0 0 0 0.2rem #b8bd0680;
                background-color: #aeb200;
                border-color: #a1a500;
                outline: none;
            }
        }
    }
}
