.slider-history {

    .slick-prev,
    .slick-next {
        transition: 200ms box-shadow ease-in;
        background-color: $white;
        border-radius: 50%;
        height: 2rem;
        width: 2rem;

        @include media-breakpoint-up(sm) {
            height: 3rem;
            width: 3rem;
        }

        &:hover,
        &:focus {
            background-color: darken($white,5%);
            box-shadow: $box-shadow-sm;
        }

        &::before {
            color: $font !important;
        }
    }
    .slick-prev {
        left: 3rem;

        @include media-breakpoint-up(sm) {
            left: 4rem;
        }
    }
    .slick-next {
        right: 3rem;

        @include media-breakpoint-up(sm) {
            right: 4rem;
        }
    }


    .slick-prev,
    .slick-next {
        //text-shadow: 0 0 8px rgba(0,0,0,0.25), 0 0 16px rgba(0,0,0,0.5);
        top: 100px;

        //&::before {
        //    transition: 300ms all ease-in;
        //    display: inline-block;
        //    margin: 0;
        //}

        //&:hover {
        //    &::before {
        //        transform-origin: center;
        //        transform: scale(1.2);
        //    }
        //}

        @include media-breakpoint-up(sm) {
            top: 140px;
        }

        @include media-breakpoint-up(md) {
            top: 160px;
        }

        @include media-breakpoint-up(lg) {
            top: 180px;
        }

        @include media-breakpoint-up(xl) {
            top: 200px;
        }
    }

    .slick-list {
        overflow: visible !important;
    }

    .slide-item {

        img {
            transition: 400ms all ease-in;
            box-shadow: 0 0 16px rgba(0,0,0,0.2);
            opacity: 0.8;
        }

        &.slick-current {
            transform: scale(1);
            overflow: visible;
            z-index: 9;

            img {
                transition: 400ms all ease-in;
                opacity: 1;
            }
        }

        &:not(.slick-current) {
            transform: scale(0.8) translateX(20%) translateY(-4%);
            transition: 400ms all ease-in;

            .slick-current + & {
                transform: scale(0.8) translateX(-20%) translateY(-4%);
                transition: 400ms all ease-in;
            }

            p {
                transition: 400ms all ease-in;
                position: absolute;
                opacity: 0;
            }
        }
    }

    .slide-item.slick-current + .slide-item:not(.slick-current) {
        transform: scale(0.8) translateX(-20%) translateY(-4%);
        transition: 400ms all ease-in;
    }
}
