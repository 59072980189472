.close-menu {
    top: 0; left: 0; right: 0; bottom: 0;
    transition: 400ms all ease-in;
    background-color: $dark;
    position: fixed;
    height: 100vh;
    z-index: -1;
    opacity: 0;

    .open-menu &,
    .open-login & {
        transition: 400ms all ease-out;
        opacity: 0.1;
        z-index: 98;
    }
}
