.list-block {
    padding: 0;
    margin: 0;

    .list-block-item {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
