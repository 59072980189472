.list-icon {
    margin: 0 0 1.5rem;
    padding: 0;

    li {
        padding: 0 0 0 2rem;
        list-style: none;
        margin: 0;

        &::before {
            margin: -0.2rem 0.3rem 0 -2.3rem;
            vertical-align: middle;
            display: inline-block;
            height: 1.7rem;
            width: 1.7rem;
            content: '';
        }
    }

    &.list-icon-check {
        li {
            &::before {
                content: url("data:image/svg+xml; utf8, <svg xmlns='http://www.w3.org/2000/svg' class='jam jam-check' preserveAspectRatio='xMinYMin' viewBox='-5 -7 24 24'><defs/><path fill='rgb(175,158,151)' d='M5.486 9.73a.997.997 0 01-.707-.292L.537 5.195A1 1 0 111.95 3.78l3.535 3.535L11.85.952a1 1 0 011.415 1.414L6.193 9.438a.997.997 0 01-.707.292z'/></svg>");
            }
        }
    }
}
