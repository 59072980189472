.header-section {
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    transition: 400ms all ease-out;
    top: 0; left: 0; right: 0;
    background: $off-white;
    position: fixed;
    color: $default;
    z-index: 100;
    height: 80px;

    &.fixed {
        box-shadow: 0 0 32px rgba($default,0.4);
        transition: 400ms all ease-in;
        position: fixed;
        z-index: 100;

        .open-menu &,
        .open-login & {
            box-shadow: none;
        }
    }

    .container-fluid {
        justify-items: center;
        align-items: center;
        position: relative;
        max-width: 1480px;
        display: flex;
    }

    .logo-container {
        .logo {
            max-width: 65vw;
            display: block;
            width: 250px;

            svg {
                height: 37px;

                path {
                    transition: 300ms all ease-in;
                }

                &:hover {

                    path {
                        fill: darken($default,25%);
                    }
                }
            }
        }
    }

    .header-login {
        transition: 300ms all ease-in;
        text-decoration: none;
        cursor: pointer;

        .link {
            display: inline-block;
            position: relative;
            margin-top: -2px;

            &::after {
                background: rgba(darken($default,25%), 0.5);
                transition: 300ms all ease-in;
                position: absolute;
                top: 100%; left: 0;
                margin-top: 1px;
                content: '';
                height: 2px;
                width: 0;
            }
        }

        svg {
            transition: 300ms all ease-in;
            height: 48px;
            width: 50px;

            path {
                transition: 300ms all ease-in;
            }
        }

        &:hover,
        .open-login & {
            color: darken($default,25%);

            .link {

                &::after {
                    width: 100%;
                }
            }

            svg {
                transform: scale(0.95);

                path {
                    fill: rgba(darken($default,25%), 0.5);
                }
            }
        }
    }
}

[data-menu-measure="child"] {
    white-space: nowrap;
}
