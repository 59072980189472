// Contact box
.contact-box {
    position: fixed;
    bottom: 25px;
    right: 50px;
    z-index: 9999;

    @media all and (max-width: 640px) {
        bottom: 15px;
        right: 15px;
    }

    &.mobile {
        top: 0; bottom: 0;
        right: 0;

        .panel {
            max-width: 100%;
            height: 100vh;
            overflow: auto;
            z-index: 9999;

            &::after {
                display: none;
            }
        }

        button {
            position: fixed;
            bottom: 15px;
            right: 15px;
            z-index: 9998;
        }
    }

    button {
        float: right;
        margin-right: 0 !important;
        padding-bottom: 0;
        font-size: 18px;
        font-weight: 400;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);

        i {
            padding-left: 10px;
            font-size: 25px;
            vertical-align: middle;
        }
    }

    .panel {
        max-width: 360px;
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
        position: relative;
        background: #fff;
        display: none;

        &::after {
            background-image: url('../img/content/contact-box-footer.png');
            background-repeat: no-repeat;
            background-position: bottom right;
            position: absolute;
            bottom: -20px;
            right: 40px;
            height: 20px;
            width: 34px;
            content: '';
        }

        .panel-header {
            position: relative;
            padding: 50px 30px 30px;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            color: #fff;
            border: 0;
            background-color: $primary;

            strong {
                font-size: 20px;
            }

            i {
                position: absolute;
                top: 10px;
                right: 5px;
                font-size: 25px;
                cursor: pointer;
            }
        }

        .panel-body {
            padding: 0;
            border: 0;

            ul {
                li {
                    padding: 20px 30px;
                    font-size: 18px;
                    border-bottom: 1px solid #e4e4e4;

                    &:last-child {
                        padding-bottom: 30px;
                        border-bottom: 0;
                    }

                    i {
                        float: left;
                        margin-right: 20px;
                        padding: 12px 0 0;
                        width: 60px;
                        height: 60px;
                        font-size: 30px;
                        text-align: center;
                        color: $primary;
                        border: 3px solid $primary;
                        border-radius: 50%;
                    }

                    .icon-whatsapp {
                        padding-top: 8px;
                        font-size: 35px;
                    }

                    strong {
                        display: block;
                        margin-top: 3px;
                        font-size: 22px;
                    }

                    a {
                        text-decoration: none;
                    }
                }
            }
        }
    }
}
