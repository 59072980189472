.body-section {

    .body-section-title {
        text-align: center;

        .title-with-logo-wrapper {
            display: inline-block;
        }

        .title {
            position: relative;
            display: inline;
        }

        @include media-breakpoint-down(md) {
            &.col-md-10 {
                padding-right: 112px;
            }
        }
    }
}
