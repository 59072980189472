$grid-gutter-width:                     2rem;
$paragraph-margin-bottom:               1.5em;

$border-radius:                         0;
$headings-font-weight:                  600;

// Font
$font-family-sans-serif:                'interstate', sans-serif;

// Colors
$primary:                               #5598cc;
$primary-light:                         #86c6ee;
$secondary:                             #96ba20;
$secondary-light:                       #d3d800;
$tertiary:                              #ec6707;
$tertiary-light:                        #f39200;
$success:                               #96ba20;
$white:                                 #ffffff;
$off-white:                             #f6f6f4;
$info:                                  #3b83c0;
$warning:                               #ebb141;
$danger:                                #d95c5c;
$default:                               #9d9c93;
$font:                                  #9d9c93;

$facebook:                              #3b5998;
$foursquare:                            #f94877;
$google:                                #dd4b39;
$linkedin:                              #0077b5;
$pinterest:                             #bd081c;
$tumblr:                                #35465c;
$twitter:                               #0f1419;
$vimeo:                                 #1ab7ea;
$youtube:                               #cd201f;
$whatsapp:                              #25d366;
$instagram:                             #833ab4;

$theme-colors: (
    "primary":                          $primary,
    "primary-light":                    $primary-light,
    "secondary":                        $secondary,
    "secondary-light":                  $secondary-light,
    "tertiary":                         $tertiary,
    "tertiary-light":                   $tertiary-light,
    "success":                          $success,
    "info":                             $info,
    "warning":                          $warning,
    "danger":                           $danger,
    "default":                          $default,
    "white":                            $white,
    "off-white":                        $off-white,
    "font":                             $font,
);


// Body
$body-bg:                               $white;
$body-color:                            darken($default, 15%);

// Headings
$headings-color:                        darken($default, 10%);


// Link
$link-color:                            inherit;
$link-decoration:                       underline;
$link-hover-color:                      inherit;
$link-hover-decoration:                 none;


// Form
$input-border-radius:                   0;
$input-focus-border-color:              lighten($font,10%);
$input-box-shadow:                      none;
$input-focus-box-shadow:                none;
$btn-focus-box-shadow:                  none;


// Buttons
$btn-border-radius:                     0;
$btn-padding-x:                         1.5rem;


// Breadcrumb
$breadcrumb-bg:                         none;
$breadcrumb-divider-color:              $default;
$breadcrumb-active-color:               $default;
$breadcrumb-padding-y:                  0;
$breadcrumb-padding-x:                  0;
$breadcrumb-margin-bottom:              2rem;
