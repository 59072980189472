// Cookies
.cookies-section {
    background-color: lighten($default,28%);
    top: 0; left: 0; right: 0;
    position: fixed;
    z-index: 101;

    .container {
        align-items: center;
        max-width: 1480px;
        min-height: 124px;
        display: flex;
    }

    ~ .header-section {
        top: 174px;

        @include media-breakpoint-up(md) {
            top: 159px;
        }

        @include media-breakpoint-up(lg) {
            top: 124px;
        }
    }

    .cookie-graphic {
        align-items: center;
        display: flex;
        width: 4rem;
    }

    .cookie-message {
        align-items: center;
        display: flex;
        flex: 1;
    }

    .cookie-buttons {
        white-space: nowrap;
        align-items: center;
        display: flex;

        .btn {
            white-space: initial;
            font-size: 1rem;
        }

        @include media-breakpoint-between(sm,lg) {
            padding-left: 4rem;
        }
    }
}
