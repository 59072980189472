.popup-section {
    top: 0; left: 0; bottom: 0; right: 0;
    transition: 300ms all ease-in;
    justify-content: center;
    flex-direction: column;
    pointer-events: none;
    align-items: center;
    overflow: hidden;
    position: fixed;
    padding: ($spacer*1.5);
    display: flex;
    width: 100%;
    z-index: -1;
    opacity: 0;

    &.active {
        transition: 300ms all ease-in;
        pointer-events: auto;
        z-index: 101;
        opacity: 1;
    }

    &::before {
        top: 0; left: 0; bottom: 0; right: 0;
        background-color: rgba(0,0,0,0.75);
        position: fixed;
        height: 100vh;
        content: '';
        width: 100%;
    }

    &.active .popup.active {
        transition: 600ms opacity ease-in 300ms;
        display: block;
        opacity: 1;
    }

    .popup {
        transition: 600ms all ease-in;
        box-shadow: 0 0 ($spacer*3) rgba(0,0,0,0.25);
        max-height: calc(100vh - (#{$spacer*3}));
        position: relative;
        max-width: 100%;
        display: none;
        margin: auto;
        width: 640px;
        opacity: 0;
        padding: 0;
        z-index: 2;

        @media all and (min-width: 1600px) {
            width: 800px;
        }

        .svg-lines {
            position: absolute;
            overflow: hidden;
            top: 0; left: 0;
            height: 100%;
            width: 100%;
        }

        &::before {
            background-color: $white;
            position: absolute;
            content: '';
            bottom: 0;
            right: 0;
            left: 0;
            top: 0;
        }

        .popup-content {
            position: relative;
            overflow: visible;
            height: 100%;
            z-index: 2;

            @media all and (min-width: 1600px) {
                .p-xxl-5 {
                    padding: 3rem !important;
                }
            }

            .h1 {
                @media all and (min-width: 768px) and (max-width: 1600px) {
                    white-space: nowrap;
                    font-size: 2rem;
                }
            }

            .h5 {
                margin: 12px 0 0;
            }
        }

        .popup-content-image {
            @include media-breakpoint-down(lg) {
                animation: float-sm 6s ease-in-out infinite;
            }

            @include media-breakpoint-up(lg) {
                animation: float-lg 6s ease-in-out infinite;
            }

            @media all and (min-width: 768px) and (max-width: 1600px) {
                padding-top: 2rem !important;
            }
        }

        .popup-close {
            transition: 300ms all ease;
            position: absolute;
            cursor: pointer;
            font-size: 2em;
            color: $white;
            right: 1.5rem;
            top: 0.75rem;
            width: 1em;
            z-index: 3;

            @media all and (min-width: 1600px) {
                top: 1.25rem;
                right: 2rem;
            }

            @include media-breakpoint-up(lg) {
                color: $white;
            }

            &:hover {
                transform: scale(1.2);
            }
        }

        .btn {
            user-select: none;
        }
    }
}
